.photoStreamOuterContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 5px;
}

.header {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
}

/* When the content is loading */
.loaderContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    text-align: center;
    width: 250px;
    padding: 10px;
}

.photoStreamContainer {
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 10px;
}

.photoContainer img {
    max-width: 100%;
    height: auto;
    border: 1px solid #000;
    border-radius: 5px;
    max-height: calc(100vh - 200px);
    object-fit: contain;
}

.photoContainer {
    display: flex;
    flex-direction: column;
    width: 45vw;
}

.photoContainer p {
    text-align: center;
    margin: 0;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
}

.controls {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
    color: #666;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: transparent;
    font-weight: bold;
    margin: 5px 10px;
    border-radius: 5px;
}

.closeButton:hover {
    outline: 1px solid #333;
    color: #333;
}

/* Style for the slider container */
.slider {
    -webkit-appearance: none;
    width: 100%;
    max-width: 400px;
    height: 8px;
    background: #ddd; /* Background color of the slider track */
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    border-radius: 5px; /* Rounded corners for the slider track */
    cursor: pointer;
}

.slider:hover {
    opacity: 1;
}

/* Style for the slider thumb */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px; /* Increased width for better visibility */
    height: 24px; /* Increased height for better visibility */
    background: #246E24; /* Color of the slider thumb */
    cursor: pointer;
    border-radius: 50%; /* Rounded corners for the slider thumb */
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Add shadow for better visibility */
    border: 2px solid #fff; /* Optional: white border for contrast */
    margin-top: -8px; /* Align the thumb with the slider track */
}

.slider::-moz-range-thumb {
    width: 24px; /* Increased width for better visibility */
    height: 24px; /* Increased height for better visibility */
    background: #246E24; /* Color of the slider thumb */
    cursor: pointer;
    border-radius: 50%; /* Rounded corners for the slider thumb */
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Add shadow for better visibility */
    border: 2px solid #fff; /* Optional: white border for contrast */
}

/* Style for the filled part of the slider track */
.slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: linear-gradient(to right, #246E24 0%, #246E24 var(--slider-progress), #ddd var(--slider-progress), #ddd 100%);
    border-radius: 5px;
}

.slider::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: linear-gradient(to right, #246E24 0%, #246E24 var(--slider-progress), #ddd var(--slider-progress), #ddd 100%);
    border-radius: 5px;
}

/* Set the custom property for the slider progress */
.slider-container {
    --slider-progress: 0%;
    align-items: center;
}
