.emailContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    min-height: 0; /* Important for flex child scrolling */
}

.emailAndButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    flex: 1;
    min-height: 0; /* Important for flex child scrolling */
}

.emailContent, .emailContentEditable {
    padding: 10px;
    flex: 1;
    width: 100%;
    min-height: 200px;
    box-sizing: border-box;
    overflow: auto;
    background-color: #fff;
    font-family: Arial, sans-serif;
    white-space: normal;
    border-radius: 5px;
    border: solid 1px #ccc;
}

/* Control paragraph spacing */
.emailContent p {
    margin: 15px 0; /* Reduce from default 16px to 8px */
}

/* Control list spacing */
.emailContent ul {
    margin: 8px 0;
    padding-left: 20px;
}

.emailContent li {
    margin: 4px 0;
}

/* Control div spacing */
.emailContent div {
    margin: 8px 0;
}

.emailContent a {
    color: #24624e;
    text-decoration: none;
}

.emailContent a:hover {
    text-decoration: underline;
}

.copyButtonContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
}

.copyButton {
    background: #246E24; /* Bootstrap primary blue for example */
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.copyHoverMessage {
    position: absolute;
    bottom: 0;
    right: 35px;
    font-size: 12px;
    width: 70px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.errorMessage {
    color: red;
}

.successMessage {
    color: green;
}
