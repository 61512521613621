/* PasswordInputField.module.css */
.passwordInputFieldContainer {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.passwordInputField {
    width: 100%;
    padding: 8px;
    border: 1px solid #999;
    border-radius: 5px;
    box-sizing: border-box;
    padding-right: 30px; /* avoid overlap with the eye symbol */
}

.passwordInputField:focus {
    outline: none;
    border: 1px solid #000;
}

.togglePasswordButton {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -30px; /* Adjust as needed */
    color: #666;
}

.togglePasswordButton:focus, .togglePasswordButton:hover {
    outline: none;
    color: #333
}

/* Hide browser's built-in password visibility icon */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear,
input[type="password"]::-webkit-clear-button,
input[type="password"]::-webkit-reveal-button {
  display: none;
}