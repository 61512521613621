.conversationBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    height: 100%;
    max-width: 900px;
    box-sizing: border-box;
    margin: 0 auto;
}

.questionSeparator {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 24px 0 12px;
}

.separatorLine {
    flex: 1;
    height: 1px;
    background-color: #bbb
}

.questionNumber {
    font-size: 14px;
    color: #666;
    padding: 4px 12px;
    background-color: #fff;
    border-radius: 12px;
    white-space: nowrap;
}

.thinkingIndicator {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    margin-right: auto;
    box-sizing: border-box;
}
