.screenShareContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    min-height: 70px;
}

.screenShareGuide {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;
}
  
.screenShareImage {
    max-height: 400px;
    max-width: 90%;
    width: fit-content;
    border: solid 1px #ddd;
    border-radius: 10px;
}
  
.browserTypeOptions {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
  
.browserType {
    cursor: pointer;
    padding: 5px;
    /* Additional styling */
}

.selectedBrowserType {
    font-weight: bold;
}
  
.screen1ShareButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px;
}

.screen2ShareButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px;
    position: fixed;
    bottom: 10px;
}

.rejectScreenShareText {
    color: #444;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
    margin: 0;
    outline: none;
    border: none;
}

.rejectScreenShareText:hover, .rejectScreenShareText:focus {
    font-weight: bold;
}