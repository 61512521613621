.sidebar {
    position: fixed;
    left: 5px;
    height: calc(100vh - 40px);
    width: 330px; 
    background: #eee;
    color: #000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    overflow-y: visible;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 8;
    margin-top: 40px;
    padding: 5px;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
    @media (max-width: 767px) {
      left: 15px;
    }
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 15px;
  box-sizing: border-box;
}

.open {
    transform: translateX(-5px);
    @media (max-width: 767px) {
      transform: translateX(-20px);
    }
}

.toggleBtn {
    position: absolute;
    top: 15px; /* Adjust position as needed */
    right: -25px; /* Place outside the sidebar */
    width: 20px; /* Match this with the negative right offset */
    background: transparent; /* Button background */
    color: #666;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    font-size: 20px;
    @media (max-width: 767px) {
      display: none; /* Hide the desktop toggle button on mobile */
    }
}

.toggleBtn:hover,
.toggleBtn:focus {
    font-size: 24px;
    color: #333
}

.mobileToggleBtn {
    display: none;
    position: absolute;
    top: 5px; /* Adjust position as needed */
    right: -0; /* Place outside the sidebar */
    width: 15px; /* Match this with the negative right offset */
    background: transparent; /* Button background */
    color: #666;
    border: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    font-size: 16px;
    @media (max-width: 767px) {
      display: flex; /* Only show on mobile devices */
    }
}

.mobileToggleBtn:hover,
.mobileToggleBtn:focus {
    font-size: 15px;
    color: #333
}

/* search bar and dropdowns to sort */
.filters {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 25px;
    box-sizing: border-box;
    justify-content: space-between;
    margin-top: 5px;
    @media (max-width: 767px) {
      padding-right: 15px; /* Hide the desktop toggle button on mobile */
    }
}

/* dropdown to sort and search for names */
.sortSelect, .searchInput {
  border-radius: 5px;
  height: 100%;
  box-sizing: border-box;
  border: solid 1px #ccc;
  outline: none;
  background-color: #fff;
}

.searchInput {
  width: 100%;
}

/* Table with each candidates name, score, date for that company */
.totalTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
}

/* Each individual candidate */
.tableEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #eee;
  width: 100%;
  padding: 3px;
  box-sizing: border-box;
  align-items: center;
}

.tableEntry:hover,
.tableEntry:focus {
  cursor: pointer;
  background-color: #fff;
}

/* The entry that is currently showing its report */
.activeEntry {
  background-color: #fff;
}

.sidebarTd {
  border: none;
}


/* Row with the name and score in row 1 and date inrow 2 */
.tableColumn1 {
    display: flex;
    flex-direction: column;
}

.tableColumn1 td {
  padding: 0;
}

.testDate {
  color: #444;
  font-size: 12px;
  font-weight: 400;
}

.candidateName {
  font-weight: 600;
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
  display: inline-block;
  max-width: 240px; /* Adjust as needed */
  width: 240px;
  vertical-align: middle; 
}

.testDate {
  display: inline-block;
  vertical-align: middle; /* Align with the name if necessary */
  white-space: nowrap; /* Prevent wrapping */
}

.tableColumn2 {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding: 0;
  align-items: center;
}

.tableColumn2 td {
  padding: 0;
}