.initialPhotoColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    row-gap: 10px;
    position: absolute;
    bottom: 10px;
}

/*fixed position due to canvas and video positioning*/
.rejectPhotoText {
    color: #444;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
    margin: 0;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
}

@media (max-width: 768px) {
    .rejectPhotoText {
        bottom: 12px;
    }
}

.rejectPhotoText:hover, .rejectPhotoText:focus {
    font-weight: bold;
}

.videoStyle {
    width: 320px;
    height: 240px;
    border-radius: 5px;
    outline: none;
}

.canvasStyle {
    width: 320px;
    height: 240px;
    border-radius: 5px;
    outline: none;
}

/*spinner for when loading camera*/
.loadingIndicator {
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    color: #333;
    position: fixed;
    bottom: 180px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.cameraSelect {
    position: fixed;
    bottom: 75px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
}