.chatInputContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 900px;
    border-radius: 5px;
    padding: 0 3px;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
    .chatInputContainer {
        bottom: 15px;
    }
}

.chatInputInnerContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 5px;
	flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%; 
    box-sizing: border-box;
    border: solid 1px #ccc;
    border-radius: 5px;
    background-color: #FFFFFF;
    position: relative;
}

.chatInput {
    resize: none;
    width: 100%;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    flex: 1;
	overflow-y: auto;
    box-sizing: border-box;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: transparent;
    margin: auto;
    line-height: 1.2;
}

.chatInput:focus {
    border: solid 1px #333;
    outline: none;
}

/*for name and email input, can't grow. Has extra padding on the right for space for send button*/
.chatInputField {
    padding: 8px 40px 8px 5px;
    min-height: 36px;

}

/*textarea element for general use, starts at 40px and can grow to max set in the main file*/
.chatTextarea {
    padding: 5px 40px 5px 5px;
    height: 36px;
    border-radius: 5px;
    font-family: Helvetica, Arial, sans-serif;
}

.errorMessage {
    color: red;
}

/*to show user their input isnt currently needed*/
.chatInputInnerContainerDisabled {
    background-color: #f0f0f0; 
}


.chatInputDisabled {
    color: #666; 
    padding: 5px;
}

/* Hover message above send button */
.sendButtonHoverMessage {
    position: absolute;
    bottom: 40px;
    right: 0;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}