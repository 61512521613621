.button {
    display: flex;
    color: #fff; 
    font-weight: bold; 
    font-size: 14px;
    border-radius: 5px; 
    cursor: pointer; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: none; 
    outline: none;
    text-align: center; 
    align-items: center;
    justify-content: center;
    background-color: #246E24;
}

/*Darken when the user hovers or tabs*/
.button:hover, .button:focus {
    background-color: #174f17;
    outline: solid 1px #333;
}   

/*remove curser and popout effect when user cant submit*/
.button:disabled,
.button:disabled:hover,
.button:disabled:focus {
    cursor: default;
    outline: none;
    box-shadow: none;
    transform: none;
    background-color: #777;
}

/*styles for each variant*/
.default {
    padding: 10px 15px; 
    margin: auto;
    gap: 5px;
}

.red {
    background-color: red;
    padding: 10px 15px; 
    margin: auto;
    gap: 5px;
}

.red:hover, .red:focus {
    background-color: #d00;
}

.send {
    padding: 5px 5px; 
    margin: 0;
    position: absolute;
    bottom: 6px;
    right: 6px;
}

.feedback {
    padding: 3px; 
    margin: 0;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

/*airoplane icon in the send button*/
.sendIcon {
    width: 16px;
    height: 16px; 
    background-color: transparent;
}

.feedbackIcon {
    width: 12px;
    height: 13px; 
    background-color: transparent;
}

.proceed {
    padding: 10px 15px; 
}

.submitCode {
    padding: 3px 12px;
    border: 1px solid #666;
}

.executeCode {
    background-color: #777;
    padding: 3px 12px;
    border: 1px solid #666;
}

.form {
    padding: 10px 15px; 
    max-width: fit-content;
    margin: 0 auto;
}

/*Darken when the user hovers or tabs*/
.executeCode:hover, .executeCode:focus {
    background-color: #555;
} 

.subtle {
    background-color: #777;
    padding: 10px 15px; 
}

/*Darken when the user hovers or tabs*/
.subtle:hover, .subtle:focus {
    background-color: #555;
} 

.reportProblem {
    background-color: #fff;
    padding: 10px 15px; 
}

.tooltip {
    padding: 5px 10px; 
}

.reportIssueSendButton {
    margin: 0 auto 5px auto;
    padding: 10px 15px; 
}

/*for report issue box*/
.reportIssueButton {
    position: fixed;
    background-color: #777;
    bottom: 15px;
    right: 15px;
    z-index: 50;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: bottom 0.3s ease-out, right 0.3s ease-out;
}

@media (max-width: 768px) {
    .reportIssueButton, .reportIssueButtonCoding {
        bottom: auto !important;
        right: 5px !important;
        top: 5px !important;
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        color: #bbb !important;
    }
}

.reportIssueButtonCoding {
    position: fixed;
    background-color: #777;
    bottom: 40px;
    right: 20px;
    z-index: 50;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/*Darken when the user hovers or tabs*/
.reportIssueButton:hover, .reportIssueButton:focus {
    background-color: #555;
} 

/*fixed positioning for the pohot taking to appear at the bottom of the screen to work around the canvas being rendered in all states*/
.takePhoto {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.takePhotoMobile {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.savePhoto {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.savePhoto:hover, .savePhoto:focus {
    transform: translateX(-50%) translateY(-2px);
}

/*for start test and finish test button*/
.startTest {
    margin: 20px auto 0 auto;
}