/* sidebar with filters */
.sidebarContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 20px;
}

.sidebarHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    margin-top: 7px; /* inline with table header */
    padding-right: 5px;
}

.addTagButton {
    cursor: pointer;
    margin-right: 10px;
}

.filterTitle {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* the search filter box */
.filterInput {
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 5px 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.filterInput:focus {
    outline: none;
    border: 1px solid #999;
}

/* specific filter */
.filterRow {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
}

.filterRow:hover, .tagFilterRow:hover {
    background-color: #fff;
}

.filterRow > input {
    cursor: pointer;
}

.filterRow > label {
    cursor: pointer;
}

/* select all filter */
.totalFilterRow {
    margin-left: 0;
}

/* for tags in the filter sidebar */
.tagFilterRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    border-radius: 5px;
    margin-top: 5px;
    padding: 5px;
}

.tagAndCheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.tagAndCheckbox > input {
    cursor: pointer;
}

.tagActions {
    display: flex;
    flex-direction: row;
}

.tagActionButton {
    cursor: pointer;
    margin-right: 10px;
}

.tagLabel {
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
}
