/* LinkContainer.module.css */
.linkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ccc;
    position: relative;
    font-size: 14px;
}

.shareableLink {
    flex-grow: 1; /* Ensures the link takes up as much space as possible */
    overflow: hidden;
    text-overflow: ellipsis; /* Ensures text does not overflow */
    white-space: nowrap;
}

.copyButton {
    margin-left: 10px; /* Space between link and button */
    background: #246E24; /* Bootstrap primary blue for example */
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.copyHoverMessage {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 12px;
    width: 60px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.errorMessage {
    color: red;
}

.successMessage {
    color: green;
}
