.ssoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.ssoHeader {
    font-weight: 500;
}

.ssoButtonsContainer {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.ssoButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 5px;
    font-size: 12px;
    color: #666;
    padding: 5px 10px;
}

.ssoButton:hover, .ssoButton:focus {
    cursor: pointer;
    outline: 1px solid #333;
}

.ssoButton img {
    width: 40px;
    padding: 5px;
}