.tabs {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
}

.tabHeaders {
    display: flex;
    background-color: #444;
    box-sizing: border-box;
    padding: 5px 0 0 5px;
}

.tabHeaders.light {
    background-color: #eee;
}
  
.tabHeader {
    min-width: 80px;
    padding: 2px 10px;
    margin: 1px 0 0 3px;
    cursor: pointer;
    background: #444;
    border-radius: 5px 5px 0 0;
    transition: all 0.3s ease;
    color: #fff;
    outline: none;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    box-shadow: none;
    border: 1px solid #666;
    border-bottom: none;
    border-left: none;
}

.tabHeader.light {
    background-color: #eee;
    color: #000;
    border: 1px solid #999;
    border-bottom: none;
    border-left: none;
}
 /* When hovering the tab */

 .tabHeader:hover {
    background: #222;
    font-weight: 700;
}

.tabHeader.light:hover {
    background: #fff;
}

/* the active tab */

.tabHeader.active {
    background: #222;
    font-weight: 700;
    border: 2px solid #666;
    border-bottom: none;
    border-left: none;
}

.tabHeader.light.active {
    background: #fff;
    border: 2px solid #999;
    border-bottom: none;
    border-left: none;
}

.tabContent {
    max-height: calc(100% - 28px); /* 27px is the height of the tab headers */
    height: calc(100% - 28px);
}

.tabContent > div {
    width: 100%;
    height: 100%; /* 100% of the tabContent which already takes into account the headers */
    max-height: 100%;
}