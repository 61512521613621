body, html, #root {
    margin: 0;
    padding: 0;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    background-color: #EDEDED;
    height: 100%;
}


/* For the video and canvas for the photo taking, done here as they are accessed throughout the test */

/* Block behind the elements when they are visible in case content gets behind them */
.cameraBackgroundActiveDesktop, .cameraBackgroundActiveMobile {
  display: flex;
  width: 100%;
  height: 330px;
  position: fixed;
  bottom: 0;
  background-color: #EDEDED;
  z-index: 2;
}

.cameraBackgroundHidden {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0; /* if you want to make it fully transparent */
  z-index: -1; /* to send it behind everything else */
  pointer-events: none; /* to prevent any interaction */
}

  /* Style when they are visible. Width is dynamic based on the video aspect ratio. Video does this automatically, canvas width is controlled in PhotoCheck.js using the video aspect ratio */
  .videoActiveDesktop, .canvasActiveDesktop {
    display: flex;
    width: 320px;
    height: auto;
    border-radius: 5px;
    outline: none;
    position: fixed;
    bottom: 110px;
  }
  
  .videoActiveDesktop {
    left: 50vw;
    transform: translateX(-400px);
  }

  .videoActiveMobile, .canvasActiveMobile {
    display: flex;
    max-width: min(90%, 320px);
    height: auto;
    max-height: 240px;
    border-radius: 5px;
    outline: none;
    position: fixed;
    bottom: 110px;
  }

  .mirror {
    transform: scaleX(-1) translateX(50%) !important;
  }

  .videoActiveMobile {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .canvasActiveDesktop {
    right: 50vw;
    transform: translateX(400px);
  }

  .canvasActiveMobile {
    right: 50%;
    transform: translateX(50%);
  }

/* Dont hide but make the elements tiny and behind the rest when 'hidden' Cant hide as then the photo taking wont work. state applied in Layout.js */
.videoHidden, .canvasHidden {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden;
  opacity: 0; /* if you want to make it fully transparent */
  z-index: -1; /* to send it behind everything else */
  pointer-events: none; /* to prevent any interaction */
}

.noSelect {
  -webkit-user-select: none;  /* Chrome, Safari, Opera */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* Internet Explorer/Edge */
  user-select: none;          /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/*Scroll bars*/

/* For Webkit browsers */
::-webkit-scrollbar {
  width: 5px; /* Control the scrollbar width */
}

::-webkit-scrollbar-track {
  background: #fff; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #999; /* Color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #999 #f1f1f1; /* thumb and track color */
}
