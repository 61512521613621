/* tableStyles.module.css */

/* Table structure */
.table {
    width: 100%;
    border-collapse: collapse;
    margin: 0px auto;
    font-family: 'Segoe UI', sans-serif;
    padding: 10px;
    box-sizing: border-box;
}

th, td {
    border: 1px solid #eee;
    padding: 5px;
    text-align: left;
    position: relative;
}

/* Header styles */
th {
    background-color: #eee;
    border: 1px solid #fff;
}

.centeredHeader {
    text-align: center;
  }
  
.leftAlignedHeader {
    text-align: left;
}

.sortHeaderClickable {
    cursor: pointer;
}

.headerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 28px;
    width: 100%;
    text-wrap: nowrap;
}

.headerContentCentered {
    justify-content: center;
}

.activeSortHeader {
    color: #246e24; /* Blue color for active sorting column */
    cursor: pointer;
}

.activeSortHeader svg {
    margin-left: 5px;
}

/* Row styles */
.sortableTableRow td {
    min-height: 28px;
    height: 28px;
}

tbody tr:nth-child(odd) {
    background-color: #fff;
}

tbody tr:nth-child(even) {
    background-color: #fff;
}

tbody tr:hover {
    background-color: #f7f7f7;
}

/* Column styles */
.boldColumn {
    font-weight: 500;
}

.boldCenteredColumn {
    text-align: center;
    font-weight: 500;
}

.centeredColumn {
    text-align: center;
}

.inputColumn {
    cursor: cell;
    min-width: 50px;
}

.centered65Column {
    text-align: center;
    min-width: 90px;
}

/* Button styles */
.nameButton {
    cursor: pointer;
    padding: 1px 5px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    outline: none;
    text-align: left;
}

.nameButton:hover,
.nameButton:focus {
    outline: solid 1px #333;
}

.actionButton {
    cursor: pointer;
    padding: 1px;
    margin: 1px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    outline: none;
}

.actionButton:hover,
.actionButton:focus {
    outline: solid 1px #333;
}

.redText {
    color: red;
}

.greenText {
    color: green;
}

/* Tag styles */
.tagLabel {
    padding: 2px 5px;
    border-radius: 5px;
    margin: 2px;
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    width: fit-content;
}

.removeTagButton {
    cursor: pointer;
}
