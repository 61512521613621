.verticalResizer {
    display: flex;
    cursor: ew-resize;
    background: #fff;
    width: 5px;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.verticalResizer:hover, .horizontalResizer:hover, .verticalResizer:active, .horizontalResizer:active {
    background: #246E24;
}

.horizontalResizer {
    display: flex;
    cursor: ns-resize;
    background: #fff;
    width: 100%;
    height: 5px;
    justify-content: center;
    align-items: center;
}

.horizontalGrip {
    background: repeating-linear-gradient(
        90deg,
        #999,
        #999 2px,
        transparent 2px,
        transparent 4px
    );
}

.verticalGrip {
    background: repeating-linear-gradient(
        0deg,
        #999,
        #999 2px,
        transparent 2px,
        transparent 4px
    );
}

.verticalResizer .verticalGrip {
    width: calc(100% - 1px);
    height: 50px;
    margin: auto 0;
}

.horizontalResizer .horizontalGrip {
    width: 50px;
    height: calc(100% - 1px);
    margin: auto;
}
