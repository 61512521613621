/* FeedbackForm.module.css */
.container {
    width: 100%;
    padding: 50px 0 10px 0;
    height: calc(100vh);
    position: relative;
    box-sizing: border-box;
  }
  
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .hidden {
    display: none;
  }
  