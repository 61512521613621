/*core element*/
.tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    gap: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    border: 1px solid #cccccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
    white-space: wrap;
    z-index: 6;
    width: 150px;
    font-size: 14px;
    font-family: Helvetica, sans-serif;
    position: relative;
}

/*main text*/
.tooltip p {
    margin: 0;
    font-size: 13px;
}

/* Title */
.tooltipTitle {
    font-weight: 700;
    font-size: 14px;
}

/* number of tooltip */
.tooltipNumber {
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-weight: 600;
}

/* Row with button and number */
.tooltipBottomRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
}

/*style of specific tooltips*/
.reportIssue {
    position: fixed;
    bottom: 65px;
    right: 15px;
}

@media (max-width: 768px) {
    .reportIssue {
        display: none;
    }
}

/*chat input area during intro and test*/
.userInputIntro, .userInputTest {
    position: absolute;
    bottom: 50px;
    left: 5px;
}

.tabs {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 140px;
}

.userInputTest {
    width: 160px;
}

/*timer at beginning and warnings of time remianing*/
.timer, .timerWarning1, .timerWarning2, .retrievedTimer, .addedTime, .followUpTimer {
    position: fixed;
    top: 50px;
    left: 75%;
    transform: translateX(-80%);
}

.timer {
    width: 200px;
}

.timerWarning2 {
    width: 160px;
}

/*progress bar in header*/
.progress {
    position: fixed;
    top: 50px;
    right: 50%;
    transform: translateX(50%);
}

/*point out coding instructions*/
.testInstructions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
}

/*point to code editor and rules*/
.codeEditor, .vsCode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 170px;
}

.vsCode {
    width: 220px;
}

/*code lanaguage dropdown*/
.ideLanguage {
    position: absolute;
    top: 50px;
    right: 95%;
    transform: translateX(100%);
    width: 170px;
}

/*code submit button*/
.ideSubmit {
    position: absolute;
    bottom: 45px;
    right: 60px;
    width: 160px;
}

/*run code button*/
.ideExecute {
    position: absolute;
    bottom: 50px;
    right: 180px;
    width: 160px;
}

/*ide popout button*/
.idePopout {
    position: absolute;
    bottom: 70px;
    left: 20px;
}

/*welcome back user to retrieved session*/
.retrievedSession {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
}

/*use before and after to enable the arrows, with different variants for more precise pointing*/
.tooltip::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -11px; 
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #bababa; 
}

.tooltip::after {
    content: ''; 
    position: absolute;
    bottom: 100%; 
    left: 50%; 
    width: 0;
    height: 0;
    margin-left: -10px; 
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent;
    border-top: none;
    border-bottom: 10px solid #fff;
}

.tooltip.arrowTop::before,
.tooltip.arrowTop::after {
    bottom: 100%; 
    top: auto;
    left: 50%;
    border-bottom: 10px solid #fff; 
    border-top: none;
}

.tooltip.arrowTopLeft::before,
.tooltip.arrowTopLeft::after {
    left: 20px; 
    margin-left: 0;
}

.tooltip.arrowTopRight::before,
.tooltip.arrowTopRight::after {
    right: 20px; 
    left: auto;
    margin-left: 0;
}

.tooltip.arrowBottom::before,
.tooltip.arrowBottom::after {
    top: 100%; 
    left: 50%;
    bottom: auto;
    border-top: 10px solid #fff; 
    border-bottom: none;
}

.tooltip.arrowBottomRight::before,
.tooltip.arrowBottomRight::after {
    top: 100%; /* Position the arrow below the tooltip */
    bottom: auto;
    border-top: 10px solid #fff; /* Arrow points downwards */
    border-bottom: none;
    right: 20px;
    left: auto;
    margin-left: 0;
}

.tooltip.arrowBottomLeft::before,
.tooltip.arrowBottomLeft::after {
    top: 100%; /* Position the arrow below the tooltip */
    bottom: auto;
    border-top: 10px solid #fff; /* Arrow points downwards */
    border-bottom: none;
    left: 20px;
    right: auto;
    margin-right: 0;
}

/* Arrow pointing to the right */
.tooltip.arrowRight::before,
.tooltip.arrowRight::after {
    top: 50%;
    right: -10px;
    margin-top: -10px; /* Adjust to align the arrow */
    border-top: 10px solid transparent; 
    border-bottom: 10px solid transparent;
    border-left: 10px solid #bababa; /* Arrow color */
}

.tooltip.arrowRight::after {
    content: ''; 
    right: -10px;
    margin-top: -9px; /* Adjust to align with the inner arrow */
    border-left: 10px solid #fff; /* Same as tooltip background */
}

/* Arrow pointing to the left */
.tooltip.arrowLeft::before,
.tooltip.arrowLeft::after {
    top: 50%;
    left: -10px;
    margin-top: -10px; /* Adjust to align the arrow */
    border-top: 10px solid transparent; 
    border-bottom: 10px solid transparent;
    border-right: 10px solid #bababa; /* Arrow color */
}

.tooltip.arrowLeft::after {
    content: ''; 
    left: -10px;
    margin-top: -9px; /* Adjust to align with the inner arrow */
    border-right: 10px solid #fff; /* Same as tooltip background */
}

/* Custom arrow style combining top left and top right arrows */
.tooltip.arrowTopLeftRight::before,
.tooltip.arrowTopLeftRight::after {
    content: '';
    position: absolute;
    bottom: 100%;
    border-bottom: 10px solid #fff;
    border-top: none;
}

.tooltip.arrowTopLeftRight::before {
    left: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.tooltip.arrowTopLeftRight::after {
    right: 20px;
    left: auto;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
