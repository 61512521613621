.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
}

.shareLinkModalContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    gap: 10px;
    padding: 20px;
    border-radius: 5px;
    background-color: #eee;
    min-width: 400px;
    width: 500px;
    max-width: 90vw;
    flex-grow: 1;
    border: solid 1px #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    font-family: 'Segoe UI', sans-serif;
    box-sizing: border-box;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
    color: #666;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: transparent;
    font-weight: bold;
    margin: 5px 10px;
    border-radius: 5px;
}

.closeButton:hover {
    outline: 1px solid #333;
    color: #333;
}

.header {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin: 10px 0;
}

.radioGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.optionLabel {
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}

.label {
    display: block;
    margin-bottom: 10px;
}

.input {
    padding: 8px;
    width: calc(100% - 16px);
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.generateLinkButtonContainer {
    display: flex;
    justify-content: center;
}

.generateLinkNote {
    font-size: 13px;
    text-align: left;
    margin-left: 25px;
    color: #666;
    box-sizing: border-box;
}

.loadingSpinner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorMessage {
    color: red;
    margin-top: 5px;
    text-align: center;
}

.successMessage {
    color: green;
    margin-top: 5px;
    text-align: center;
}

/* the shareable link */
.linkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 5px;
    border-radius: 4px;
    margin: 10px 0;
}

.shareableLink {
    flex-grow: 1; /* Ensures the link takes up as much space as possible */
    overflow: hidden;
    text-overflow: ellipsis; /* Ensures text does not overflow */
    white-space: nowrap;
    position: relative;
}

.calendarInput {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.calendarInput input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}