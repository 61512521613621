/* outer container */

.treeView {
    padding: 5px;
    width: 100%;
    font-size: 12px;
    overflow-y: auto;
}

.dark {
    background-color: #222;
    color: #fff;
}

.light {
    background-color: #fff;
    color: #000;
}

/* SCHEMA header */

.mainHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    padding: 2px 5px;
    height: 19px; /* inline with the top ribbon */
    border-bottom: 1px solid #000; /* line between db and header */
}

.refreshButton, .refreshButtonLoader {
    background-color: inherit;
    outline: none;
    border: none;
    padding: 2px 5px;
    margin-right: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
}

.refreshButton.light {
    color: #000;
}

/* Schema elements */

.databases {
    list-style-type: none;
    padding: 5px 0 5px 5px;
    margin: 0;
}

.schemas, .tables, .columns {
    list-style-type: none;
    padding-left: 15px;
    padding-top: 5px;
}

.tables {
    margin: 0;
}

.schema, .table, .column {
    padding: 5px 0;
}

/* Icons */
.tableIcon {
    color: #90caf9; /* Color for the table icon */
}

.columnIcon {
    color: #d8d9a0; /* Color for the column icon */
}

.loader {
    display: flex;
    align-items: center;
    flex-direction: column;
}