/* Outer container for all the subSkills */
.subSkillSummaryContainer {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
}

/* Container of each specific subSkill */
.subSkillColumn {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.subSkillColumnPdfMode {
    margin-bottom: 0;
}

/* Container for name and question mark icon */
.subSkillNameContainer {
    display: flex;
    align-items: flex-end;
}

.subSkillName {
    display: flex;
    align-items: flex-end;
    color: #444;
    font-weight: 500;
}

.subSkillNamePdfMode {
    font-size: 12px;
    max-height: fit-content;
    height: fit-content;
}

.questionMarkIcon {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    color: #999;
}

.infoIcon:hover + .tooltipText {
    visibility: visible;
    opacity: 1;
}

.tooltipText {
    visibility: hidden;
    background-color: #fff;
    color: #000;
    border: solid 1px #ddd;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    min-width: 180px;
    min-height: 60px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    font-family: 'Segoe UI', sans-serif;
}

/* change position of the last one so it doesnt go over the border */
.lastTooltipText {
    transform: translateX(-100%);
}

.firstTooltipText {
    transform: translateX(0%);
}

/* Container of the subskill bar and the arrow */
.barAndArrowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Adjusts space between bar and arrow */
    align-items: center; /* Vertically centers items in the container */
    width: 100%;
    gap: 10px;
}

/* Container of the score bar to enable colouring of background */
.subSkillBarContainer {
    display: flex;
    height: 3px;
    border-radius: 2.5px;
    background-color: #ddd;
    width: 100%;
    border-radius: 5px;
}

/* The score bar itself */
.subSkillBar {
    display: block;
    height: 100%;
    border-radius: 5px;
}

/* The arrow to expand or collapse the details */
.detailsArrow {
    cursor: pointer; /* Indicates this is clickable */
    color: #444;
}

/* Container that appears when arrow is clicked with the score description and rational */
.subSkillExpandedContent {
    margin: 0;
    border-bottom: solid 1px #ddd;
    padding-bottom: 5px;
}

.scoreDescription {
    font-weight: 500;
    padding-bottom: 5px;
}

.scoreDescriptionPdfMode {
    font-size: 12px;
}

.rationaleContainer {
    position: relative;
    font-family: 'Segoe UI', sans-serif;
}

.rationale {
    color: #444;
    position: relative;
}

.trailingSpace {
    display: inline-block; /* Ensures it doesn't cause a new line */
    width: 45px; 
    height: 10px;
}

.rationalePdfMode {
    font-size: 11px;
}

