.totalScoreContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.scoreText {
    font-size: 26px; 
    font-weight: bold;
    position: absolute;
    bottom: 0;
}

.scoreTextPdfMode {
    font-size: 22px;
}