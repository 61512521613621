.accessDeniedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    text-align: center;
    color: #000; 
  }
  
.accessDeniedHeader {
    font-size: 2rem;
    margin-bottom: 1rem;
}
  
.accessDeniedText {
    font-size: 1rem;
}
  