.navbar {
    position: fixed;
    top: 40px;
    right: 20px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border: solid 1px #ccc;
    z-index: 20;
    border-radius: 5px;
    box-sizing: border-box; /* Ensure padding and border are included in width */
    max-width: 300px;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box; /* Consistent box sizing */
}

.menu li {
    width: 100%;
}

.logOutItem {
    border-top: solid 1px #ccc;
}

.link {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 10px; /* Adjust padding for more space */
    box-sizing: border-box; /* Include padding in width */
    color: #333; /* Ensure text color is visible */
}

.linkIcon {
    display: flex;
    justify-content: center;
    width: 20px;
    margin-right: 5px;
}

.link:hover, .link:focus {
    background-color: #e0e0e0; /* Use a color that indicates hover */
    outline: none;
    color: #000;
}

/* Account summary */
.accountSummary {
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 10px;
    border-bottom: solid 1px #ccc;
    box-sizing: border-box;
}

.userIcon {
    width: 30px;
    height: 30px;
    border-radius: 50%; /* Make it circular */
    background-color: #246E24;
    color: white;
    font-size: 14px;
    font-weight: 500;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.userIcon:hover, .userIcon:focus {
    background-color: #1a4d1a; /* Darken the color on hover */
    outline: solid 1px #333;
}

.userInfo {
    display: flex;
    flex-direction: column;
}

.userName {
    font-size: 14px;
    font-weight: 500;
}

.userEmail {
    font-size: 12px;
    color: #666;
}