/* Main container where all elements are inside */
.reportInterfaceOuterContainer {
    display: flex;
    flex-direction: row;
}

.reportInterface {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 1400px;
    min-width: 300px;
    margin: 50px auto 5px auto;
    border-radius: 5px;
    gap: 10px;
    padding: 0 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    @media (max-width: 767px) {
        padding: 0 20px;
    }
}

/* Legacy report interface with smaller max-width */
.reportInterfaceLegacy {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 1100px;
    min-width: 900px;
    margin: 50px auto 5px auto;
    border-radius: 5px;
    gap: 10px;
    padding: 0 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    @media (max-width: 767px) {
        padding: 0 20px;
    }
}

.reportInterfaceShift {
    margin-left: calc(330px + (100vw - 1060px - 350px) / 2);
    margin-right: auto;
}

.reportInterfacePdfMode {
    padding: 10px;
    font-size: 11px;
    min-width: 970px;
    margin: 0;
    border-radius: 5px;
}

.reportInterfaceCover {
    display: flex;
    min-height: 100%;
    min-width: 100%;
    z-index: 7;
    background-color: #EDEDED;
    position: absolute;
    top: 0;
}

/* for the loading spinner when initial data is being fetched */
.loadingSpinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

/* Common elements and fonts */
/* - Font Weight: 400 normal, 500 medium, 700 bold */
/* - Font Colour: #444 grey, icons #444 */

h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
}

h3 {
    margin: 0;
    font-weight: 700;
}

h2 {
    margin: 10px auto;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
}

p {
    margin: 0;
}

/* Buttons on right hand side */

/* the container */
.sideButtonsContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: calc(100% - 25px); /* Position it to the right of the interface */
    gap: 5px;
    z-index: 5;
    align-items: flex-start;
}

@media (max-width: 800px) {
    .sideButtonsContainer {
        display: none;
    }
}

/* standard button style */
.sideButton {
    height: 25px;
    display: flex;
    width: 25px;
    cursor: pointer;
    font-size: 18px;
    color: #444;
    position: relative;
}

.sideButton:hover {
    color: #000;
}

.reviewButton {
    font-size: 14px;
}

.pdfError {
    font-size: 12px;
    border: solid 1px #ddd;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    width: 220px;
    color: #f44336;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    font-weight: 500;
}

/* standard hover message */
.sideButtonHoverMessage {
    position: absolute;
    left: calc(100% - 150px);
    font-size: 12px;
    width: 110px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.accessibilityHoverMessage {
    position: absolute;
    left: 0;
    font-size: 12px;
    width: 160px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    color: #000;
    z-index: 2;
}

.pdfButtonHoverMessage {
    top: 10px;
}

.shareButtonHoverMessage {
    top: 10px;
}

.reviewButtonHoverMessage {
    top: 10px;
}

/* PDF Sections for printing */

.pageBreakAfter {
    page-break-after: always;
}

.pdfSection1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 800px) {
    .pdfSection1New {
        flex-direction: column!important;
    }
}

.pdfSection1New {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.pdfSection1PdfMode {
    gap: 5px;
}

.pdfSectionPdfMode {
    margin-top: 5px;
}

/* when not in dpfmode */
.pdfSection {
    max-width: 100%;
}

.headerAndDivider {
    display: flex;
    flex-direction: column;
}

/* specific to the transcript header */
.transcriptHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.transcriptHeaderText {
    font-size: 16px;
    font-weight: 700;
}

.transcriptHeader p {
    font-size: 14px;
    color: blue;
    cursor: pointer;
}

.transcriptHeader p:hover {
    font-weight: 700;
}

.sectionDivider {
    display: block;
    border: none; 
    height: 1px;
    background-color: #000;
    margin: 0 0 10px 0;
}

.outOfTimeDivider {
    display: block;
    border: none; 
    height: 1px;
    background-color: red;
    margin: 0 0 10px 0;
}

/* SECTION 1: SUMMARY SECTION WITH TOTAL SCORE, NAME, KEY FACTS */

/* Container with name, and score, feedback and test facts containers */
.summaryContainer, .summaryContainerReviewMode {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
}

.summaryContainerNew {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;
    flex-basis: 0;
    width: 33%;
    max-width: 33%;
}

@media (max-width: 800px) {
    .summaryContainerNew {
        width: 100%;
        max-width: 100%;
    }
}

.summaryContainerPdfMode {
    gap: 5px;
    padding: 5px;
}

.candidateSummaryAndScore {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-end;
}

@media (max-width: 1260px) {
    .candidateSummaryAndScore {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 800px) {
    .candidateSummaryAndScore {
        flex-direction: row;
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .candidateSummaryAndScore {
        flex-direction: column;
        align-items: center;
    }
}

.nameAndFeedback {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
}

.feedbackHeader {
    font-size: 14px;
    font-weight: 700;
}

.nameAndFeedbackPdfMode {
    gap: 3px;
}

.candidateNameContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    margin: 5px 0;
    overflow: hidden;
}

.candidateNameContainerNew {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    margin: 5px 0;
    overflow: hidden;
    max-width: 190px;
}

@media (max-width: 1260px) {
    .candidateNameContainerNew {
        max-width: 100%;
    }
}

.nameAndTags {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

/* for the email and date */
.nameSubHeader, .nameSubHeaderNew {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 12px;
    color: #444;
    flex-wrap: wrap;
}

.nameSubHeaderNew {
    flex-direction: column;
}

.candidateNameContainerPdfMode {
    font-size: 11px;
}

.candidatePhotoFrame, .candidatePhotoFrameNew {
    display: flex;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 2;
}

.candidatePhotoFrameNew {
    bottom: 25px;
    top: auto;
    right: auto;
    left: 15px;
}

.cameraInactiveRationalPopout, .screenShareInactiveRationalPopout {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 2;
    min-width: 150px;
    font-size: 12px;
}

.cameraInactiveHeading {
    font-size: 12px;
    font-weight: 700;
}

.candidatePhoto {
    display: flex;
    width: 200px;
    border-radius: 5px;
}

.candidatePhotoStream {
    display: flex;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: wrap;
    gap: 10px;
    z-index: 10;
    border: solid 1px #ddd;
    background-color: #fff;
    max-width: 95vw;
    max-height: 95vh;
}

/* popout for shareable link config */
.shareLinkModal, .reportTagModal {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: solid 1px #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    font-family: 'Segoe UI', sans-serif;
}

/* Score summary */

.scoreSummaryPdfMode {
    gap: 10px;
}

.scoreAndRank {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 180px;
}

/* Container of total score pie (need to see why is needed) */
.totalScore {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Container where the visual renders */
.totalScorePie {
    height: 90px;
    width: 180px;
}

.totalScorePiePdfMode {
    height: 60px;
    width: 120px;
}

.rank {
    font-weight: 700;
    color: #444;
}

/* Text with the summary feedback across the center of the top */
.overallFeedback {
    flex: 1;
    color: #444;
    font-family: 'Segoe UI', sans-serif;
    position: relative;
}


.trailingSpace {
    display: inline-block; /* Ensures it doesn't cause a new line */
    width: 45px; /* Specifies the desired space */
    height: 10px;
}

.overallFeedbackPdfMode {
    font-size: 12px;
}

/* ANTI FRAUD */

/* outer container */
.antiFraudSummary, .antiFraudSummaryNew {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    height: fit-content;
    min-width: fit-content;
    font-family: 'Segoe UI', sans-serif;
}

.antiFraudSummaryNew {
    width: 100%;
    padding: 0;
}

.antiFraudSummaryPdfMode {
    gap: 5px;
    padding: 5px;
    font-size: 11px;
    width: 180px;
}

/* Header with summary of risk */
.integrityHeader {
    display: flex;
    font-weight: 600;
    align-items: center;
    gap: 5px;
}

/* Overall risk font colours */
.greenRisk {
    color: #00B050;
}

.amberRisk {
    color: #FF8C00;
}

.redRisk {
    color: #ff0000;
}

/* container with details by measure */
.cheatingContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    color: #666;
    font-size: 13px;
}

/* row for each measure */
.integrityRow, .integrityRowNew { 
    display: flex; /* To align the icon and text properly */
    align-items: center; /* Center items vertically */
    gap: 5px;
}

.integrityRowNew {
    width: 100%;
    position: relative;
}

/* the measures icon */
.integrityIcon {
    display: flex;
    position: relative;
}

.integrityIconNew {
    display: flex;
}

/* The measures text including the dynamic risk */
.integrityText, .integrityTextNew {
    display: flex;
    flex-direction: row;
    gap: 3px;
    padding: 2px;
    width: 100%;
}

.integrityTextNew {
    justify-content: space-between;
    align-items: center;
}

.integrityTextClickable, .integrityTextClickableNew {
    display: flex;
    flex-direction: row;
    gap: 3px;
    cursor: pointer;
    border-bottom: 1px dotted #444;
    padding: 2px;
    width: 100%;
}

.integrityTextClickableNew {
    justify-content: space-between;
    align-items: center;
}

.integrityTextClickable:hover, .integrityTextClickable:focus {
    outline: solid 1px #333;
    border-bottom: 1px dotted transparent;
    border-radius: 5px;
}

.greenFraudSummary {
    color: #00B050;
    font-weight: 500; 
}

.greenFraudButton {
    color: white;
    background-color: green;
    padding: 5px 10px;
    border-radius: 5px;
    min-width: 25px;
    text-align: center;
}

.redFraudSummary {
    color: #ff0000;
    font-weight: 500;
}

.redFraudButton {
    color: white;
    background-color: red;
    padding: 2px 5px;
    border-radius: 5px;
    min-width: 25px;
    text-align: center;
}

/* Inside test facts, container with the icon before the fact, like the clock for time taken */
.factWithIcon, .customTags {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    max-width: 190px;
}

@media (max-width: 1260px) {
    .factWithIcon {
        max-width: 250px;
    }
}

        
.factWithIcon p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accessibilityModeIcon {
    font-size: 0.9rem;
    color: green;
    margin-left: 5px;
    position: relative;
}

.fraudIcon, .detailIcon {
    width: 18px;
    align-items: center;
}

.customTagContainer {
    display: flex;
    flex-direction: row;
    outline: none;
    border: none;
}

.customTags {
    border-radius: 5px;
    padding: 2px;
    outline: solid 1px #ddd;
}

/* SECTION 2: BREAKDOWN OF SKILLS */

/* Container holding all the skill and subskill summarries */
.scoreDetail, .scoreDetailNew {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-sizing: border-box;
}

.scoreDetailNew {
    height: fit-content;
    flex-grow: 2;
    flex-basis: 0;
    padding: 20px;
}

/* To manage each div (which is a scoreDetailContainer) inside */
.scoreDetail > div {
    flex: 1; /* Allows each child to grow equally */
    min-width: 0; /* Prevents flex items from overflowing their container */
}

/* Container for each set of Skill and SubSkill summaries */
.scoreDetailContainer, .scoreDetailContainerNew {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 20px;
    box-sizing: border-box;
    min-width: 130px;
    width: 100%;
}

.scoreDetailContainerNew {
    padding: 0;
}

.scoreDetailContainerPdfMode {
    padding: 5px;
}

/* Target all scoreDetailContainer but the last to add a border, no need for last as the container ends */
.scoreDetailContainer:not(:last-child) {
    border-right: 1px solid #ddd; /* Adjust color and width as needed */
}


/* SECTION 3: TEST INSTRUCTIONS, CODE AND CHAT TRANSCRIPT AND THE DETAILED SCORING BESIDE EACH */

/* Container with all the above */
.transcriptContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: hidden;
    background-color: #ededed;
    gap: 10px;
    box-sizing: border-box;
}

/* Style for each sub container to ensure uniformity. Outer containers with both the scores and transcripts within */
.chatDetail, .codeDetail, .instructionsDetail, .missingDetail, .communicationDetail, .chatDetailNew {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    background-color: #ededed;
    box-sizing: border-box;
    gap: 10px;
}

.chatDetailNew {
    gap: 10px;
}

@media (max-width: 800px) {
    .chatDetailNew {
        flex-direction: column;
    }
}

/* the code editor and console */
.codeOutputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Style for each sub container to ensure uniformity. Containers with the transcripts */
.instructionsContainer, .chatboxContainer, .codeEditorContainer, .missingContainer, .communicationContainer {
    flex: 2;
    display: flex;
    max-width: 75%;
}

.chatboxContainerNew {
    flex-grow: 2;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

@media (max-width: 800px) {
    .chatboxContainerNew {
        max-width: 100%;
    }
}


/* if review mode make the containers full width */
.reviewMode {
    min-width: 100%;
}

/* Style for each sub container to ensure uniformity. Containers where the tables with the subskill detail appear */
.chatScoring, .codeScoring, .instructionsScoring, .missingScoring {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 250px;
    flex: 1;
    box-sizing: border-box;
}

.chatScoringNew {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    box-sizing: border-box;
}

@media (max-width: 800px) {
    .chatScoringNew {
        max-width: 100%;
    }
}

.chatScoringPdfMode, .codeScoringPdfMode, .instructionsScoringPdfMode, .missingScoringPdfMode {
    max-width: 180px;
}

.instructionsContainer {
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    font-family: 'Segoe UI', sans-serif;
}

.chatboxContainerPdfMode {
    font-size: 12px;
}

/* Code editor to show the code */
.codeEditorContainer {
    min-height: 700px;
    display: flex;
    flex-direction: column;
    gap: 0
}

.codeEditorContainer.database {
    min-height: 800px;
}

.codeEditor {
    display: flex;
    border-radius: 5px;
    box-sizing: border-box;
    max-height: 550px;
    height: 100%;
}

.codeConsole {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 10px;
    max-height: 150px;
    overflow: auto;
    font-size: 12px;
}

.schemaViewer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 5px;
    background-color: #222;
    color: #F8F8F2;
    max-height: 150px;
    overflow: auto;
}

/* Box at the top of the codeEditorContainer which shows the language used */
.codeLanguage {
    padding: 5px;
    background-color: #666;
    color: #ededed;
    font-family: monospace;
}

/* Container with chat messages */
.chatboxContainer {
    flex-direction: column;
}

/* Out of time section */
.outOfTimeHeading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.outOfTimeText {
    color: red;
    font-size: 16px;
    font-weight: bold;
}

.clockIconOutOfTime {
    color: red;
    margin-right: 5px;
}

