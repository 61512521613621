/*overall container*/
.messageContainer {
	display: flex;
    align-items: flex-start;
	flex-wrap: nowrap;
    font-family: 'Segoe UI', sans-serif;
}

/*make user message on the right*/
.messageContainerUser {
    justify-content: flex-end;
}

/*container for text*/
.messageBox {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin: 0 0 10px 0;
	padding: 0;
    width: auto;
    max-width: 90%;
    position: relative;
    white-space: pre-wrap;
    min-height: 14px;
}

/* When used in the report */
.fullWidth {
    max-width: 100%;
  }

.messageBoxSystem {
    background-color: #ffffff;
}

.messageBoxUser {
    background-color: #b8e4a8;
    display: flex;
    justify-content: flex-end;
}

.messageText {
    padding: 5px;
    margin: 0px;
    line-height: 1.2rem;
}

.messageText.code {
    white-space: pre-wrap;
    color: #000 !important;
}

.messageText pre,
.messageText code[class*="language-"],
.messageText pre[class*="language-"] {
    color: #666 !important; /* Force black color */
    text-shadow: none;
    line-height: 1rem;
    font-size: .85rem;
    border-radius: 5px;
    word-wrap: break-word;
    text-wrap: wrap;
}

/* Ensure inline code is also black */
.messageText code {
    color: #666 !important;
    font-size: .85rem;

}

.messageText pre {
    margin: 0 10px;
    padding: 0 5px;

}

.messageText a {
    color: inherit;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
}

.messageText ul, .messageText ol {
    margin: 0;
    white-space: normal;
}

.instructions {
    line-height: 1rem;
    white-space: pre-wrap;
}

.instructions ul {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.instructions code {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 700;
}

.instructions table {
    border-collapse: collapse;
}

.instructions th, .fullInstructions td {
    padding: 3px;
    border: 1px solid #eee;
    background-color: #fff;
}

/* Space for feedback icons */
.trailingSpace {
    display: inline-block; /* Ensures it doesn't cause a new line */
    width: 45px; /* Specifies the desired space */
    height: 10px;
}

/*icons for system messages*/
.iconContainer {
	display: flex;
    height: 30px;
	border-radius: 50px;
	font-size: 12px;
    max-width: 30px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fcde81;
    margin-right: 5px;
    font-weight: 600;
}

/*expand long messages*/
.messageText.collapsed {
    max-height: 250px;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px; 
}

.messageText.expanded {
    max-height: none;
    padding-bottom: 20px; 
}

.messageBox.expanded, .messageBox.collapsed  {
    padding-bottom: 20px; 
}

.buttonContainer {
    border-radius: 5px;
    cursor: pointer;
    text-align: right;
}

.toggleButton {
    color: #0a79ff;
    font-weight: bold;
    padding: 1px 1px;
    background-color: inherit;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
}

.codeToggleButton {
    color: #fff;
}

/*add margin to first message to push them down to bottom*/
.firstMessage {
    margin-top: auto;
}

/*message that contains the code submission after the coding challenge*/
.codeSubmission {
    background-color: #272822; /* matches dark theme colour */
    color: #fff; 
    font-family: 'Courier New', Courier, monospace; /* Monospaced font for code */
    white-space: pre-wrap; /* Preserve formatting and line breaks */
    margin: 0 0 10px 0;
    padding: 5px;
}

.codeSubmission pre {
    margin: 0;
    padding: 5px;
}

.codeSubmission pre,
.codeSubmission code[class*="language-"],
.codeSubmission pre[class*="language-"] {
    color: #fff !important; /* Force black color */
    text-shadow: none;
    line-height: 1rem;
    font-size: .85rem;
    border-radius: 5px;
    word-wrap: break-word;
    text-wrap: wrap;
}

.loaderContainer {
    padding: 5px;
}