.container {
    display: flex;
    border: 1px solid #ddd;
    background-color: #1e1e1e;
    border-radius: 4px;
    overflow: hidden;
    height: 400px;
    box-sizing: border-box;
}

.fileExplorer {
    min-width: 200px;
    max-width: 250px;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    box-sizing: border-box;
    flex: 1;
}

.editor {
    flex: 2;
    min-width: 300px;
    box-sizing: border-box;
    width: 100%;
}