.editorContainer {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: calc(100% - 140px); /*minus the height of the ribbons and output box*/;
    font-size: 12px;
    font-family: monospace;
    border-bottom: 2px solid #000;
    border-top: 2px solid #000;
    position: relative;
    box-sizing: border-box;
}

.editorContainer.tabsPresent {
    height: calc(100% - 170px); /*minus the height of the ribbons and output box*/;
}

/* make it 100% when it is for the candidate report */
.editorContainer.candidateReport {
    height: 100%;
}

.monacoEditorContainer .monaco-editor {
    outline: none;
    border: none;
}

.languageServerLoaderContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 7;
    gap: 10px;
    text-align: center;
}

.languageServerLoaderContainer p {
    font-size: 14px;
    color: #999;
}