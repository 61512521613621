
/* Main container deciding the grid */
.outerContainer {
    display: grid;
    grid-template-areas:
        "header header header"
        "banner banner banner"
        "ribbon ribbon ribbon"
        "sidebar sibebarButton content";
    grid-template-rows: 41px auto auto 1fr; /* Adjust heights as needed */
    grid-template-columns: 280px 30px 1fr; /* Sidebar width and content flexibility */
    height: 100vh;
    width: 100%;
    font-family: 'Segoe UI', sans-serif;
    background-color: #fff;
    box-sizing: border-box;
}

/* if there is no sidebar present */
.noSidebar {
    grid-template-areas:
        "header"
        "banner"
        "ribbon"
        "content";
    grid-template-columns: 1fr;
}

.sidebarHidden {
    grid-template-areas:
        "header header"
        "banner banner"
        "ribbon ribbon"
        "sibebarButton content";
    grid-template-columns: 30px 1fr; /* Sidebarbutton width and content flexibility */
}

.header {
    grid-area: header;
}

.banner {
    grid-area: banner;
}

.ribbon {
    grid-area: ribbon;
}

.sidebar {
    grid-area: sidebar;
    overflow: auto;
}

.sidebarButton {
    grid-area: sibebarButton;
}

/* the main content of the page which can overflow */
.contentContainer {
    grid-area: content;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
}

.tableContainer, .pendingUsersContainer {
    border: solid 1px #ccc;
    background-color: #eee;
    margin: 5px 0 20px 0;
    width: 100%;
    overflow-x: auto;
    min-height: 85px;
    box-sizing: border-box;
}

.noPendingRequests {
    margin: 5px 0 20px 0;
}

.adminPageTableHeader {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin: 5px;
    box-sizing: border-box;
}

.plusIcon {
    font-size: 16px;
    margin-right: 5px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f0f0f0;
}

.table tbody tr:hover {
    background-color: #f9f9f9;
}

/* filters */
.filterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin: 5px;
}
  
.filterContainer select,
.filterContainer input {
    padding: 5px;
    font-size: 14px;
    outline: 1px solid #999;
    border-radius: 5px;
    border: none;
}

.filterContainer select {
    cursor: pointer;
}

.filterContainer select:focus, .filterContainer input:focus {
    outline: solid 2px #333;
}
  
.search, .filter {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/* colours */
.redText {
    color: red;
}

.greenText {
    color: green;
}

/* my account and company pages */
.userProfileContainer, .companyProfileContainer {
    display: flex;
    flex-direction: column;
    margin: 5px auto 20px auto;
    border: solid 1px #ccc;
    border-radius: 5px;
    align-items: center;
}

.userOverview, .userCompanyOverview, .companyOverview, .companyLogoOverview {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 20px;
    border-radius: 5px;
    align-items: center;
}

.userOverview, .companyOverview {
    background-color: #eee;
}

.companyOverview {
    min-width: 300px;
}

.largeUserIcon {
    width: 80px;
    height: 80px;
    border-radius: 50%; /* Make it circular */
    background-color: #246E24;
    color: white;
    font-size: 28px;
    font-weight: 500;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.userCompanyLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
}


.userCompanyLogo img {
    max-width: 100% !important;
    max-height: 100% !important;
}

.userDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.userName, .companyName {
    font-size: 22px;
    font-weight: 700;
}

.deleteAccountButton, .changePasswordButton {
    color: red;
    padding: 5px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto 10px auto;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.changePasswordButton {
    color: blue;
}

.deleteAccountButton:hover, .deleteAccountButton:focus {
    outline: solid 1px red;
}

.changePasswordButton:hover, .changePasswordButton:focus {
    outline: solid 1px blue;
}

.userDetailContainer, .userDetailWithIconContainer, .companyDetailContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    align-items: center;
    min-width: 220px;
}

.userDetailWithIconContainer {
    justify-content: space-between;
}

.userDetail {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.userDetailName {
    font-weight: 500;
}

.inputField {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.deleteButton {
    margin-top: 20px;
    background-color: red;
    color: white;
}

.uploadPlaceholder {
    display: flex;
}

.myCompanyLogo {
    max-width: 200px !important;
}


.currentCompanyLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.companyLogoLabel {
    font-size: 16px;
    font-weight: 500;
}

/* documentation page */

/* specific width for the documentation page */
.documentationContent {
    max-width: 600px;
}

/* for the first heading in the documentation */
.contentContainer h2:first-of-type {
    margin-top: 5px; 
}

/* images for the documentation */
.contentContainer img {
    max-width: 600px;
    min-width: 200px;
    margin: 5px 0;
}

.contentContainer ul {
    margin: 10px;
}

.contentContainer h2 {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    margin: 20px 0 10px 0;
}

.contentContainer h3 {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin: 20px 0 10px 0;
}

.documentationNav {
    display: flex;
    flex-direction: column;
    margin: 10px 10px 0 0;
    box-sizing: border-box;

}

.documentationNav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.navMainHeading {
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.navSubHeading {
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.navMainHeading:hover, .navMainHeading:focus, .navSubHeading:hover, .navSubHeading:focus {
    background-color: #fff;
    color: #333;
}

.navMainHeading a, .navSubHeading a {
    color: #000;
    text-decoration: none;
}

/* Styling for the active navigation item */
.activeNavItem {
    background-color: #fff;
    color: #333;
}

.scrollToTopButton {
    position: absolute;
    right: 18px;
    bottom: 60px;
    cursor: pointer;
    background-color: rgba(176, 176, 176, 0.3); /* Semi-transparent grey */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 4; 
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    visibility: hidden;
    border: 1px solid #666;
    color: #666;
}

.visible {
    visibility: visible;
}

.scrollToTopButton.light {
    background-color: #bbb; /* darker so you can see with the light theme */
}

.scrollToTopButton:hover, .scrollToTopButton:focus {
    background-color: #246E24;
    outline: solid 1px #333;
    color: #fff;
}

/* for the summary page */
.companySummaryOuterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
    margin: 0 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.companySummaryContainer {
    flex: 1;
    min-width: 350px;
    max-width: 500px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.noTestDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    height: 100%;
    width: 100%;
    font-size: 16px;
}

.noTestDataHeader {
    font-size: 24px;
    font-weight: 700;
}

.noTestDataButton {
    margin-top: 20px;
}

.tableErrorMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px;
    font-size: 16px;
    text-align: center;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 5px;
}

.emailVerificationStatusContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100%;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}