.audioMessage {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    max-width: 90%;
}

.user {
    margin-left: auto;
    background-color: #b8e4a8;
}

.assistant {
    margin-right: auto;
    background-color: #fff;
    position: relative;
    min-width: 100px;
}

/* Position duration for assistant messages */
.assistant .duration {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 11px;
    color: #666;
}

.playButton {
    width: 30px;
    height: 30px;
    font-size: 18px;
    color: #333;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.playButton:hover, .playButton:active {
    color: #000;
}

.playButton.unplayed {
    color: green;  
    animation: pulse 2s infinite;
}

.playButton.disabled {
    color: #666;
    cursor: not-allowed;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.messageRecording {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.draftMessageRecording {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

/* Progress bar */
.progressBar {
    flex: 1;
    height: 4px;
    min-width: 100px;
    background: #eee;
    border-radius: 2px;
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    outline: 1px solid #ddd;
}

.progressBar.noDuration {
    outline: 1px solid #ddd;
}

/* Larger hit area for progress bar */
.progressBar::before {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    z-index: -1;
}

.progressBar:hover, .progressBar:active {
    outline: 1px solid #333;
}

.progress {
    height: 100%;
    background: #075E54;
    border-radius: 2px;
    transition: width 0.1s linear;
    position: relative;
}

/* Handle */
.progressHandle {
    width: 12px;
    height: 12px;
    background: green;
    border-radius: 50%;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    cursor: grab;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
}

.progressHandle.noDurationHandle {
    background: #666;
}

/* Larger hit area for handle */
.progressHandle::before {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    z-index: -1;
}

.progressHandle:active {
    cursor: grabbing;
    transform: translateY(-50%) scale(1.1);
}

/* Add a hover effect on the progress bar */
.progressBar:hover .progressHandle {
    transform: translateY(-50%) scale(1.1);
}

/* Duration */

.duration {
    font-size: 12px;
    color: #666;
    text-align: right;
}

.messageText {
    flex: 1;
    margin-left: 10px;
    color: #333;
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
}

.cursor {
    display: inline-block;
    margin-left: 2px;
    animation: blink 1s step-end infinite;
}

@keyframes blink {
    from, to {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

/* Draft */
.draftContainer {
    background-color: transparent;
}

