/*General Rules*/
.srOnly {
    display: none;
}

.popupContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  /* Just center it */
    max-width: 500px;
    min-width: 300px;
    width: calc(100% - 20px);  /* Account for spacing on sides */
    max-height: 95vh;
    overflow-y: auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.5);
    z-index: 4;
	box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    row-gap: 20px;
    font-family: 'Segoe UI', sans-serif;
}

.popupContainerOverflowVisible {
    overflow-y: visible !important;
}

.wide {
    max-width: min(90vw, 900px);
    background-color: #eee;
}

.popupContainerLoading {
    display: none;
}

/* for container with splitcontent */
.splitPopupContent {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.popupContentSplitForm, .popupContentSplitMarketing {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    box-sizing: border-box;
    padding: 20px;
    max-width: 50%;
    height: 100%;
    min-width: 300px;
}

.popupContentSplitForm {
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #ccc;
}

.popupContentSplitMarketing {
    align-items: center;
    justify-content: space-between;
}

/* popup text */

.popupTextLarge {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    max-width: 500px;
}

.popupTextGrey {
    font-size: 14px;
    text-align: center;
    color: #666;
}

.popupText {
    font-size: 14px;
    text-align: center;
    margin: 0;
}

.popupTextBold {
    font-size: 14px;
    text-align: center;
    margin: 0;
    font-weight: bold;
}

.popupTextLeft {
    font-size: 14px;
    text-align: left;
}

.multipleButtonsContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

/* Logos in the terms popup */

.logos {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.logoContainer {
    display: flex;
    width: 50%;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.partnerLogo, .degrauLogoTermsPopup {
    width: auto;
    max-height: 40px;
    max-width: min(100%, 200px);  /* Changed from 200px to use min() function */
}

/* Logo in other popups */

.degrauLogoPopup {
    width: 280px;
    height: auto;
}

.degrauLogoPopupLarge {
    width: 400px;
    height: auto;
}

.degrauLogoPopupSmall {
    width: 150px;
    height: auto;
}

/*popout modules e.g. T&Cs details or guide to turn on camera*/
.termsModal {
    min-height: 200px;
    height: 100%;
    overflow: auto;
    background-color: #fff;
    border: solid 1px #666;
    border-radius: 5px;
}

.modalContent {
    margin: 0;
    padding: 10px;
}

.modalContent h2 {
    font-size: 24px;
}

.modalContent h3 {
    font-size: 20px;
}

.close {
    color: red;           
    float: right;
    font-size: 24px;
    font-weight: bold;    
}

.close:hover, .close:focus {
    color: darkred;    
    text-decoration: none;
    cursor: pointer;
}

/*Popup: Terms*/

/*blue text to popout terms*/
.showTerms, .showGuide {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.showTerms:hover, .showGuide:hover {
    font-weight: bold;
}

/* checkbox style*/
.termsCheckboxContainer input[type="checkbox"]:focus {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1); 
}

.termsCheckboxContainer {
    display: flex;
    align-items: center;
    margin-right: auto;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
}

.termsCheckboxContainer input[type="checkbox"] {
    margin-right: 10px; 
}

.termsCheckboxAndErrorContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 0;
    width: 100%;
}

.termsError {
    color: red;
    margin: 0 0 0 10px;
}

/*Popup: Select test*/

/*Popup: Reject photo*/
.rejectPhotoButtonContainer, .restartTestButtonContainer {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.rejectPhotoTextarea {
    width: 100%; 
    padding: 5px;
    font-size: 14px;
    margin-top: 10px;
    outline: none;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-y: auto;
    height: 100px;
    font-family: Helvetica, sans-serif;
}

.rejectPhotoTextarea:focus, .rejectPhotoTextarea:hover {
    outline: 1px solid #333;
}

.rejectPhotoForm {
    display: flex;
    flex-direction: column;
    width: 100%; 
    height: 150px;
}

.errorText {
    color: red;
    margin: 0;
    text-align: center;
}

.successText {
    color: green;
    margin: 0;
    text-align: center;
}

/* restart test request */
.startFromBeginningForm {
    display: flex;
    flex-direction: column;
    width: 100%; 
}

.restartFormAndButton {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.startFromBeginningForm textarea {
    height: 100px;
    font-family: Helvetica, sans-serif;
    resize: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid #999;
    padding: 5px;
}

.startFromBeginningForm label {
    margin-bottom: 5px;
    text-align: center;
}

.sendingMessageContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

/* Container with all input fields */
.inputFieldContainer {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
    row-gap: 20px;
}

/* Wider form size */
.inputFieldContainerWide {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;
    row-gap: 20px;
}

/* Form to handle two input fields */
.inputFieldContainerDouble {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}

/* container for two input fields */
.doubleInputFieldContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.togglePasswordButton {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 5px;
    background-color: #F0F0F0;
    border-radius: 5px;
    border: 1px solid #999999;
}

/* indivudual input fields */
.inputFieldAndLabel {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.inputFieldAndLabelNarrow {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    max-width: 250px;
    min-width: 130px;
    width: 100%;
}

/* specific password field for the password and eye symbol */
.passwordInputContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    row-gap: 5px;
    position: relative;
}

.forgotPasswordText {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    padding: 2px;
}

.forgotPasswordText:hover, .forgotPasswordText:focus {
    font-weight: bold;
}

.passwordRules {
    font-size: 13px;
    color: #333;
}

.passwordRules ul {
    padding-left: 20px;
    margin: 0;
}

/*dropdowns*/
.dropdownContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
}

.dropdownContainer label {
    font-weight: bold;
}

.dropdown {
    background-color: #F0F0F0;
    border-radius: 5px;      
    border: 1px solid #999999;  
    padding: 5px;   
    color: #000000;
    cursor: pointer;
}

.selectionError {
    color: red;
    margin: 0 auto 0 0;
}

/*hyperlinks*/
.link {
    font-weight: bold;
}
  
.leftAlignedList {
    text-align: left;
    margin: 0; /* Center the div itself */
}

.leftAlignedList ul {
    text-align: left;
    margin: 0 auto; /* Center the list itself */
    padding-left: 20px; /* How far in the bullet is */
    list-style-type: disc; /* Enables bullets */
}

.leftAlignedList ul li {
    margin-bottom: 5px; /* Space between list items */
    padding-left: 10px; /* How far in the text is from the bullet */
}

/* retry otice test in testInterrupted popup */
.retryNotice {
    margin: 20px 0;
}

/* SSO */

.ssoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}