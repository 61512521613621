/* Outer box that container skill name, score semi cirle and score text*/
.skillSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    gap: 10px;
}

/* Make tighter in pdf mode */
.skillSummaryPdfMode {
    margin-bottom: 0;
    gap: 0;
}
  
/* Name of the skill, above the semi circle, and the weight */
.skillHeader {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
}

.skillName {
    display: flex;
    font-weight: 700;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 100%;
}

.skillNamePdfMode {
    font-size: 12px;
}

.skillWeight {
    color: #444;
    font-size: 12px;
    text-align: right;
    font-weight: 500;
}

.skillWeightPdfMode {
    font-size: 11px;
}

/* Container for the score semi cirlce and the score text */
.semiCircleContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 60px;
    width: 100%;
}

.scoreSemiCircle {
    display: flex;
    justify-content: flex-end;
    margin: auto;
    position: relative;
    min-width: 60px;
    max-height: 60px;
    width: 100%;
    height: 100%;
}

.scoreText {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    color: #444;
}

