.sqlLogOutput {
    height: 100%;
    font-size: 12px;
}

.mainHeading {
    font-size: 14px;
    font-weight: bold;
    padding: 2px 5px;
}

.header {
    display: flex;
    font-weight: bold;
    padding: 5px 0;
    border-bottom: 1px solid #999;
    border-top: 1px solid #999;
}

/* Green tick or Red X */

.headerIcon, .logIcon {
    width: 20px;
    text-align: center;
    margin-right: 8px;
}

.logIcon {
    font-size: 16px; /* Make the icons bigger */
}

/* the number of the action */
.headerId, .logId {
    width: 30px;
    margin-right: 8px;
}

/* the time of the action */

.headerTime, .logTime {
    width: 70px;
    margin-right: 8px;
}

/* the action and the result message */

.headerAction, .headerMessage, .logAction, .logMessage {
    flex: 1;
    margin-right: 8px;
}

.logAction, .logMessage {
    white-space: nowrap; /* Prevents text wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Shows ellipsis for overflow */
}

/* Each row of the log */

.logEntry {
    display: flex;
    align-items: center;
    padding: 2px 0;
    border-bottom: 1px solid #999;
}

.logEntry:nth-child(even).dark {
    background-color: #222;
    color: #fff;
}

.logEntry:nth-child(odd).dark {
    background-color: #444;
    color: #fff;
}

.logEntry:nth-child(even).light {
    background-color: #fff;
    color: #000;
}

.logEntry:nth-child(odd).light {
    background-color: #eee;
    color: #000;
}

.logEntry:hover {
    background-color: #666;
}

.dark {
    background-color: #222;
    color: #fff;
}

.light {
    background-color: #fff;
    color: #000;
}
