.banner {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    padding: 5px;
    width: 100%;
    color: #000;
    font-weight: bold;
    text-align: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
}