.hoverMessage {
    position: absolute;
    font-size: 12px;
    padding: 8px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    z-index: 12;
    color: #000;
  }
  
  .right {
    left: calc(100% - 10px); /* Adjust position to the left */
  }
  
  .left {
    right: calc(100% - 10px); /* Adjust position to the right */
  }

  .topRight {
    bottom: calc(100% - 10px); /* Adjust position to the right */
    left: calc(100% - 10px); /* Adjust position to the left */
  }
  
  .hoverPopoutHeading {
    font-weight: 700;
    margin-bottom: 4px;
  }
  
  .hoverPopoutText {
    font-weight: 400;
    overflow-wrap: break-word; /* Enables word breaking */
  }
  
  .noWrapText {
    white-space: nowrap; /* Disables wrapping for measurement */
  }
  
  .wrapText {
    white-space: normal; /* Enables wrapping if text exceeds the max width */
  }
  