.inputFieldContainer {
    width: 100%;
}

.inputField {
    width: 100%;
    padding: 8px;
    border: 1px solid #999;
    border-radius: 5px;
    box-sizing: border-box;
}

.inputField:focus {
    outline: none;
    border: 1px solid #000;
}
  