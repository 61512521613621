.pageSummaryContainer {
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border-radius: 5px;
  height: 520px;
}

.pageSummaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid #fff;
}

.dataList {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  overflow-y: auto;
}

.dataItem {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  gap: 5px;
}

.dataItem:hover, .dataItem:focus {
  background-color: #e6f7e6;
  border: 1px solid #333
}

.notClickable {
  cursor: default;
  pointer-events: none;
}

.notClickable:hover, .notClickable:focus {
  background-color: #fff;
  border-color: #ddd;
}

.dataTitle {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.dataDetails {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 5px;
  color: #333;
  font-size: 12px;
  flex-wrap: wrap;
}

.pageSummaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: auto;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 20px;
  height: 100%;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

.noDataHeader {
  font-size: 24px;
  font-weight: 700;
}