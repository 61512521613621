/* for the theme and language dropdowns*/
.codeDropdown {
    text-align: left;
    text-align-last: left;
    float: left;
    clear: both;
    outline: none;
    padding: 2px 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    min-width: 120px;
    box-sizing: border-box;
    height: fit-content;
}

.dark {
    border: 1px solid #999;
    background-color: inherit;
    color: #ddd;
}

.light {
    border: 1px solid #666;
    background-color: #999;
    color: #fff;
}

/* for the dropdown options */

.codeDropdown.dark option {
    background-color: #000;
    color: #fff;
}

.codeDropdown.light option {
    background-color: #666;
    color: #fff;
}