.topRibbon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    margin: 0;
    padding: 20px 10px;
    gap: 10px;
}

.pageHeader {
    font-size: 22px;
    font-weight: 700;
}