.audioCheck {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
}
  
.audioCheckTitle {
    text-align: center;
    font-weight: 500;
}

.testSection {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}

.testContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    padding: 20px;
    background-color: #eee;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    min-height: 206px;
}

.containerTitle {
    font-weight: 500;
}

.speakerTestText, .micTestText {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    height: 88px;
}

.micTestText {
    height: 30px;
}

.micFeedback {
    text-align: center;
    padding: 10px;
    min-height: 40px;
}
  
.volumeMeter {
    height: 20px;
    background-color: #4CAF50;
    margin-bottom: 10px;
    transition: width 0.1s ease;
}

.deviceSelect {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.audioCheckError {
    color: red;
    font-size: 14px;
    text-align: center;
}

.volumeVisualizerContainer {
    width: 70%;
    height: 20px;
}
