.rolePlayInterface {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-family: 'Segoe UI', sans-serif;
    background-color: #f5f5f5;
    box-sizing: border-box;
}

.title {
    grid-area: title;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin: 10px auto;
    box-sizing: border-box;
}

.characterContainer, .transcriptContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    min-height: 300px;
    gap: 30px;
    box-sizing: border-box;
    margin: 0 auto;
}

.startRoleplayButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.transcriptContainer {
    min-height: 300px;
    overflow-y: auto;
}

.transcriptTitle, .characterTitle {
    font-size: 18px;
    font-weight: 500;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
}

.transcript {
    overflow-y: auto;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 100%;
}

.transcript div {
    margin: 5px 0;
}

.emptyTranscript {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    color: #666;
    text-align: center;
    box-sizing: border-box;
    height: 100%;
    margin: 0 !important;
}

.instructionsContainer {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
    gap: 10px;
    padding-bottom: 10px;
    flex-grow: 1;
}

.subTitle {
    font-size: 16px;
    font-weight: 500;
}

.connectionError {
    background-color: #fff;
    color: #dc2626;
    padding: 0.75rem;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-weight: 500;
}

.resumedMessage {
    background-color: #fff;
    color: darkgreen;
    padding: 0.75rem;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-weight: 500;
}

.instructions {
    font-size: 14px;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
}

.instructions ul {
    margin: 10px 0;
}

.instructions p {
    margin: 10px 0;
}

.characterImage {
    width: 150px;
    height: auto;
    border-radius: 50%;
}

@keyframes pulseBorder {
    0% { outline-width: 2px; }
    50% { outline-width: 8px; }
    100% { outline-width: 2px; }
}

/* Add dynamic glow around the image based on amplitude of the ai speech */
.aiSpeakingWithAmplitude {
    box-shadow: 0 0 2px 1px var(--glow-color, #246e24), /* minimum glow (h offset, v offset, blur, spread) */
                0 0 var(--amplitude-blur, 10px) var(--amplitude-spread, 2px) var(--glow-color, #246e24);
    transition: box-shadow 0.1s ease-in-out;
}

.statusIndicator {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: #000;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    align-items: center;
    gap: 5px;
}

.controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
    gap: 10px;
    border-radius: 5px;
}

.userSpeakingWithAmplitude {
    box-shadow: 0 0 2px 1px var(--glow-color, #246e24),
                0 0 var(--amplitude-blur, 10px) var(--amplitude-spread, 2px) var(--glow-color, #246e24);
    transition: box-shadow 0.1s ease-in-out;
}

.voiceButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.voiceButtonAndText {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.voiceButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #fff;
    background-color: #666;
    outline: none;
    border: none;
}

.voiceButton:hover, .voiceButton:focus {
    cursor: pointer;
    outline: 1px solid #333;
}

@keyframes subtlePulse {
    0% {
        box-shadow: 0 0 2px 1px var(--glow-color, #246e24),
                   0 0 4px 1px var(--glow-color, #246e24);
    }
    50% {
        box-shadow: 0 0 2px 1px var(--glow-color, #246e24),
                   0 0 8px 4px var(--glow-color, #246e24);
    }
    100% {
        box-shadow: 0 0 2px 1px var(--glow-color, #246e24),
                   0 0 4px 1px var(--glow-color, #246e24);
    }
}

.listeningPulse {
    animation: subtlePulse 2s ease-in-out infinite;
}

.green {
    background-color: green;
}

.red {
    background-color: red;
}

.voiceButton:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.connected {
    color: green;
}

.disconnected {
    color: red;
}

.buttonText {
    font-size: 12px;
    text-align: center;
}
