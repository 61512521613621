.outputArea {
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    font-size: 12px;
}

.dark {
    background-color: #272822;
    color: #fff;
}

.light {
    background-color: #fff;
    color: #000;
}

.mainHeading {
    font-size: 14px;
    font-weight: bold;
    padding: 2px 5px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 3px;
    text-align: left;
}

/* Table rows */

.table tr.dark:nth-child(even) {
    background-color: #272822;
    color: #fff;
}

.table tr.dark:nth-child(odd) {
    background-color: #444;
    color: #fff;
}

.table tr.light:nth-child(even) {
    background-color: #fff;
    color: #000;
}

.table tr.light:nth-child(odd) {
    background-color: #ddd;
    color: #000;
}

.table tr:hover {
    background-color: #666;
}

.table th {
    background-color: #f2f2f2;
}

.dark .table th {
    background-color: #272822;
}

.dark .table td, .dark .table th {
    border-color: #555;
}
