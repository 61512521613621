.marketingContainer {
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 5px;
    border: solid 1px #ccc;
    background-color: #e6f7e6;
    font-family: 'Segoe UI', sans-serif;
}

.marketingHeader {
    border-bottom: 1px solid #000;
}

.marketingTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}

.marketingDetails ul {
    padding-left: 10px;
}

.marketingDetails li {
    margin-bottom: 10px;
    font-size: 16px;
}
