.adminPage {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.outerContainer {
  align-items: flex-start;
  gap: 10px;
}

.layoutRow {
  display: flex;
  flex-direction: row;
}

.layoutColumn {
  display: flex;
  flex-direction: column;
}

.outerConfigContainer, .mainConfigContainer, .comparisonConfigContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.configContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  align-items: center;
  width: 100%;
}
.header {
  font-size: 2em;
  margin-bottom: 20px;
}

.selectContainer {
  margin-bottom: 5px;
}

.selectDropdown:focus {
  outline: 1px solid #000;
}

.selectLabel {
  font-weight: bold;
}

.selectDropdown, .input {
  margin: 0 10px;
  padding: 5px;
  font-size: 1em;
  border-radius: 5px;
  outline: none;
  border: 1px solid #999;
}

.input {
  width: 40px;
}

.commitMessage {
  margin: 10px auto;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #999;
  outline: none;
  box-sizing: border-box;
}

.configHeader {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.textarea {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 1em;
  font-family: monospace;
  box-sizing: border-box;
  border-radius: 5px;
}

.statusMessage {
  margin-top: 10px;
  font-weight: bold;
}
