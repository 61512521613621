.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
}

.tagModalContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    background-color: #eee;
    border: solid 1px #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    font-family: 'Segoe UI', sans-serif;
}

/* Red x to close the modal */
.closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    color: #666;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: transparent;
    font-weight: bold;
    margin: 0;
    border-radius: 5px;
}

.closeButton:hover {
    outline: 1px solid #333;
    color: #333;
}

/* sub headers */
.subheader {
    font-size: 16px;
    color: #333;
    margin: 10px 0;
    font-family: 'Montserrat', sans-serif;
}

/* Container with dropdown of tags or input for new tag and button to execute */
.tagAndButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
}

.tagAndColourContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    flex: 1;
}

/* input for new tag */
.input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.colorPicker {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}
  
.colorOption {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    border: none;
    cursor: pointer;
}

.colorOption.selected {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    border: 2px solid green
}

.customColorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}


.textColorSelector {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.customColorPicker, .textColorButton {
    cursor: pointer;
}
  
.textColorButton {
    height: 30px;
    width: 30px;
    border: solid 1px #999;
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.6;
}
  
.textColorButton.selected {
    border: 2px solid green; 
    opacity: 1; 
}
