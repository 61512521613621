/* Outer container for all the subSkills */
.subSkillSummaryContainer {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
}

/* Container of each specific subSkill */
.subSkillColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    border-bottom: solid 1px #ddd;
}

.subSkillSummary {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.subSkillColumnPdfMode {
    margin-bottom: 0;
}

/* container with the bar*/
.subSkillBarAndScoreContainer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    gap: 2px;
    width: 25%;
    min-width: 120px;
    box-sizing: border-box;
}

/* Container of the score bar to enable colouring of background */
.barContainer {
    display: flex;
    height: 3px;
    border-radius: 2.5px;
    background-color: #ddd;
    width: 100%;
    border-radius: 5px;
}

.scoreContainer {
    display: flex;
    font-size: 12px;
}

/* Container for name and question mark icon */
.subSkillNameAndArrowContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 3;
    flex-basis: 0;
    width: 75%;
}

.subSkillNameContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 5px;
}

.subSkillName {
    display: flex;
    align-items: flex-end;
    font-weight: 600;
}

.subSkillNamePdfMode {
    font-size: 12px;
    max-height: fit-content;
    height: fit-content;
}

.questionMarkIcon {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    color: #999;
}

.infoIcon:hover + .tooltipText {
    visibility: visible;
    opacity: 1;
}

.tooltipText {
    visibility: hidden;
    background-color: #fff;
    color: #000;
    border: solid 1px #ddd;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    min-width: 180px;
    min-height: 60px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    font-family: 'Segoe UI', sans-serif;
}

/* change position of the last one so it doesnt go over the border */
.lastTooltipText {
    transform: translateX(-100%);
}

.firstTooltipText {
    transform: translateX(0%);
}

/* The score bar itself */
.subSkillBar {
    display: block;
    height: 100%;
    border-radius: 5px;
}

/* The arrow to expand or collapse the details */
.detailsArrow {
    cursor: pointer; /* Indicates this is clickable */
    color: #444;
}

/* Container that appears when arrow is clicked with the score description and rational */
.subSkillExpandedContent {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 0;
    padding-bottom: 5px;
}

.scoreDescription {
    font-weight: 500;
    padding-bottom: 5px;
}

.scoreDescriptionPdfMode {
    font-size: 12px;
}

.subSubSkillSummaryContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    width: 25%;
    min-width: 120px;
    gap: 10px;
    margin: 0;
    padding-bottom: 5px;
    box-sizing: border-box;
}

/* Sub-sub-skill styles */
.subSubSkillItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
    padding-left: 20px;
    box-sizing: border-box;
}

.subSubSkillNameContainer {
    display: flex;
    margin-bottom: 4px;
}

.subSubSkillName {
    font-size: 12px;
    font-weight: 500;
    color: #666;
}

.subSubSkillBarContainer {
    display: flex;
    width: 100%;
}

/* Custom bar for sub-sub-skills */
.subSubSkillBarContainer .barContainer {
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.subSubSkillBarContainer .subSkillBar {
    height: 4px;
    border-radius: 4px;
    transition: width 0.3s ease-in-out;
}

.rationaleAndDefinitionContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    flex-basis: 0;
    width: 75%;
    gap: 10px;
    position: relative;
    font-family: 'Segoe UI', sans-serif;
}

.definitionContainer, .rationaleContainer {
    width: 100%;
}

.definition, .rationale {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: #999;
}

.rationale {
    color: #444;
    position: relative;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    margin-top: 3px;
}

.trailingSpace {
    display: inline-block; /* Ensures it doesn't cause a new line */
    width: 45px; 
    height: 10px;
}

.rationalePdfMode {
    font-size: 11px;
}

.subSubSkillsHeader {
    font-size: 13px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid #eee;
}

