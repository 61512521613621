.testPageOuterContainer {
    display: grid;
    grid-template-areas:
        "header"
        "main";
    grid-template-rows: 41px 1fr; 
    height: 100%;
    width: 100%;
    font-family: 'Segoe UI', sans-serif;
    background-color: #fff;
    box-sizing: border-box;
}

.testPageHeader {
    grid-area: header;
    box-sizing: border-box;
}

/* the main content of the page which can overflow */
.testStageMainContainer {
    grid-area: main;
    box-sizing: border-box;
    overflow: auto;
}