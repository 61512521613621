/* FEEDBACK */

.feedbackComponent {
    position: relative;
}

/* Container for feedback icons */
.feedbackIconContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: relative;
    margin-top: -15px;
    height: 15px;
}


.feedbackIconContainer.chatMessage {
    margin: -20px 5px 0 5px;
}

/* Thumb icons */

.feedbackIcon {
    color: #999;
    cursor: pointer;
}

.feedbackIconPos {
    margin-left: auto;
}

/* When hovered or tab or clicked change colour based on sentiment */

.feedbackIconPos:hover,
.feedbackIconPos:focus {
    color: green;
}

.feedbackIconNeg:hover,
.feedbackIconNeg:focus {
    color: red;
}

.thumbUpClicked {
    color: green;
}

.thumbDownClicked {
    color: red;
}

/* Hover messages for thumbs */

.thumbUpHoverMessage {
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 2;
    color: #000;
}

.thumbDownHoverMessage {
    position: absolute;
    top: 30px;
    right: 0px;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 2;
    color: #000;
}

/* Overal feedback input container */

.feedbackInputContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: 5px;
}

/* for popout mode */
.feedbackInputContainer.popout {
    padding: 5px;
    position: absolute;
    bottom: -100px;
    right: 0;
    z-index: 2;
    width: 300px;
    border-radius: 10px 0 0 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 5px;
}

/* X button to close the feedback input */

.closeIcon {
    cursor: pointer;
    position: absolute;
    right: 1px;
    top: 5px;
    z-index: 2;
    background-color: #fff;
    padding-right: 5px;
    color: #999;
}

.closeIcon.svg {
    width: 10px;
    height: 10px;
}

.closeIcon.popout {
    top: 10px;
    right: 6px;
    left: auto;
}

.closeIcon:hover,
.closeIcon:focus {
    color: #444;
}

/* Container for the feedback input and button to send */
.feedbackInput {
    width: 100%;
    display: flex;
    flex-direction: row; 
    outline: none;
    resize: none;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
}

/* Area where the user types the feedback */
.feedbackTextArea {
    width: 100%;
    outline: none;
    border: solid 1px #ccc;
    resize: none;
    border-radius: 5px;
    padding-right: 25px;
    font-family: 'Segoe UI', sans-serif;
    overflow: auto;
    min-height: 80px;
    scrollbar-color: #999 #fff;
}

/* Thank you and error messages */

.feedbackThankYou {
    padding: 5px;
    position: absolute;
    right: 0;
    z-index: 2;
    border-radius: 10px 0 0 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 5px;
}

.feedbackError {
    color: red;
    font-size: 12px;
}