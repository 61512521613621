.resultsSummaryTable {
    width: 100%;
    border-collapse: collapse;
    margin: 0px auto;
    font-family: 'Segoe UI', sans-serif;
    padding: 10px;
    box-sizing: border-box;
}

th, td {
    border: 1px solid #eee;
    padding: 5px;
    text-align: left;
    position: relative;
}


/* candidate name that when clicked opens the report */
.nameButton {
    cursor: pointer;
    padding: 1px 5px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    outline: none;
    text-align: left;
}

.nameButton:hover, .nameButton:focus {
    outline: solid 1px #333;
}

/* action buttons */

.redText {
    color: red;
}

.greenText {
    color: green;
}

/* tags */

.tagLabel {
    padding: 2px 5px;
    border-radius: 5px;
    margin: 2px;
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    width: fit-content;
    outline: 1px solid #ddd;
}

.removeTagButton {
    cursor: pointer;
}
/* if no data */
.errorMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px;
    font-size: 16px;
    text-align: center;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 5px;
}