.uploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    background-color: #f5f5f5; /* Light grey background */
    border: 1px solid #e0e0e0; /* Border similar to the image */
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.fileSelected {
    background-color: #fff;
    border: none;
}

.uploadIconAndMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 150px;
}

.uploadIcon {
    font-size: 24px; /* Size of the upload icon */
    color: #333; /* Dark color for the icon */
    margin-bottom: 5px; /* Space between icon and text */
}

.uploadText {
    font-size: 14px; /* Text size */
    color: #333; /* Text color */
    font-weight: 500; /* Slightly bolder text */
}

.fileInput {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.changeFileInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.changeFileInput {
    cursor: pointer;
}

.previewContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.previewImageContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.previewHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    box-sizing: border-box;
    min-height: 30px;
}

.imagePreview {
    max-width: 200px !important;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.errorMessage {
    color: red;
    margin-top: 10px;
    font-size: 12px;
}
