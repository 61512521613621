/* src/sharedStyles/AdminFormsStyles.module.css */

.inputGroup {
    margin-bottom: 15px;
}

.inputGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.inputGroup input,
.inputGroup select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-sizing: border-box;
}

.emailInputWrapper {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.emailInputWrapper input {
    flex: 1;
    border: none;
    padding: 5px;
    font-size: 14px;
    outline: none;
    min-width: 150px;
    background-color: transparent;
}

.emailTag, .selectedUserTag {
    display: inline-flex;
    align-items: center;
    background-color: #bdf7cd;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    font-size: 14px;
    width: fit-content;
}

.emailTag button, .selectedUserTag button {
    margin-left: 5px;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: #333;
    border-radius: 5px;
}

.emailTag button:hover, .selectedUserTag button:hover {
    color: #000;
    outline: solid 1px #000;
}

.roleMessage {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
}

.selectedUsersList {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.checkboxContainer {
    display: flex !important;
    align-items: center;
    margin-top: 10px;
}

.checkboxLabel {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.checkboxContainer input {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.headerAndRadioOptions {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.radioGroupHeader {
    font-size: 14px;
    font-weight: bold;
}

.radioGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.radioOptionWithDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.radioOption {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.radioOptionDetail {
    color: #333;
    font-size: 13px;
    padding-left: 25px;
}


.optionLabel {
    cursor: pointer;
}

.disabledDateInput {
    background-color: #eee !important;
    color: #aaa !important;
    pointer-events: none; /* Prevent user interaction */
}

/* password form */
.passwordRules {
    font-size: 14x;
    color: #333;
    margin-bottom: 15px;
}