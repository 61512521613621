/* TagSelector.module.css */

.tagSelector {
    position: absolute;
    top: 0;
    right: 60px;
    background-color: #eee;
    z-index: 10;
    border: solid 1px #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    font-family: 'Segoe UI', sans-serif;
    padding: 25px 20px 20px 20px;
    cursor: auto;
}

/* Red x to close the modal */
.closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 2px 5px;
    color: #666;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: transparent;
    font-weight: bold;
    margin: 0;
    border-radius: 5px;
}

.closeButton:hover {
    outline: 1px solid #333;
    color: #333;
}

.tagList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tagItem {
    padding: 5px 10px;
    margin: 5px 0;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.2s, color 0.2s;
    min-width: 60px;
}

.tagItem:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.tagItem svg {
    margin-right: 5px;
}
