.interviewOverviewContainer {
    max-width: 100%;
    width: 800px;
    padding: 20px;
    border-radius: 5px;
    font-family: 'Segoe UI', sans-serif;
    background: #fff;
    font-size: 14px;
    box-sizing: border-box;
}
/* header with title and core details */

.interviewOverviewHeader {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 5px;
    border-bottom: solid 1px #ccc;
}

.interviewTitleContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.interviewTitle {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}

/* the description of the test */

.interviewSummary {
    color: #333;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
}

/* the container with the sections and skills */

.interviewOverviewBody {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

/* subtitles like sections or skills */

.interviewSubtitle {
    font-size: 16px;
    font-weight: 500;
}

/* container with less important details */
.interviewDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.interviewSummaryIcon {
    font-size: 14px;
    margin-right: 5px;
}

/* the actual details */
.interviewDetail {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #333;
}

.interviewSections, .interviewSkills {
    padding: 10px;
}

/* the container with the sections */
.interviewSections {
    flex: 2;
    border-right: solid 1px #ccc;
}

.interviewSkills {
    flex: 1;
}

/* the list of sections and skills */
.sectionsList, .skillsList {
    margin: 0;
    padding-left: 20px;
}

.skillsList {
    list-style-type: disc;
    min-width: 250px;
}

.sectionsList {
    min-width: 300px;
}

/* the specific section item */
.sectionItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px 0;
}

.sectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

/* the icon to expand te section */
.expandSectionIcon {
    font-size: 14px;
    cursor: pointer;
}

.sectionName {
    font-size: 14px;
    font-weight: 500;
}

.sectionTime {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    color: #333;
}

/* the details visible even when the section is not expanded */
.sectionSummaryDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 12px;
    color: #333;
    gap: 5px;
}

.sectionDetail {
    font-size: 12px;
    color: #333;
}

/* the section that appears when the section is expanded */

.sectionExpanded {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 5px;
    border-bottom: solid 1px #ccc;
}

.skillItem {
    font-size: 14px;
    margin: 5px 0;
}
