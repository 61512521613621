/* Main container */

:root {
    --instructions-height: 50%; /* Initial height for the instructions area */
    --instructions-width: 33%;  /* Initial width for the instructions area */
}

.backendInterface {
    display: grid;
    grid-template-areas:
        "instructions verticalResizer devContent"
        "horizontalResizer verticalResizer devContent"
        "chatbox verticalResizer devContent";
    grid-template-rows: var(--instructions-height) 5px 1fr; /* Adjustable row heights */
    grid-template-columns: var(--instructions-width) 5px 1fr; /* Adjustable column widths */
    height: calc(100vh - 44px);
    width: 100%;
    max-width: 100%;
    font-family: 'Segoe UI', sans-serif;
    background-color: #fff;
    box-sizing: border-box;
}

.backendInterfaceCover {
    height: 100%;
    width: 100%;
    z-index: 2;
    opacity: 0;
    position: fixed;
    background-color: #000;
}

/* when loading the dev environment */
.devEnvCover {
    height: 100vh;
    width: 100vw;
    z-index: 11;
    background-color: #333;
    position: fixed;
    top: 0;
    left: 0;
}

/* Grid components */

.instructionsContainer {
    grid-area: instructions;
    padding: 3px;
    overflow: auto;
    box-sizing: border-box;
    position: relative;
    background-color: #eee;
}

.horizontalResizerContainer {
    grid-area: horizontalResizer;
    box-sizing: border-box;
}

.backendChatbox {
    grid-area: chatbox;
    box-sizing: border-box;
    background-color: #eee;
    overflow: auto;
}

.verticalResizerContainer {
    grid-area: verticalResizer;
    box-sizing: border-box;
}

.devContent {
    grid-area: devContent;
    box-sizing: border-box;
    max-width: 100%;
    overflow: auto;
}

/* detailed elements */

/* instructions */

.fullInstructions {
    line-height: 1rem;
    white-space: normal;
}

.fullInstructions p {
    margin: 10px 0 0 0;
}

.fullInstructions ul, .fullInstructions ol {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin: 5px 0;
    white-space: normal;
}

.fullInstructions code {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 700;
}

.fullInstructions table {
    border-collapse: collapse;
}

.fullInstructions th, .fullInstructions td {
    padding: 2px;
    border: 1px solid #ccc;
    background-color: #eee;
}

.instructionsHeader {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
}

/* resizing */

/* Overlay so that the iframe does not block mouse events */
.resizeOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Invisible */
    z-index: 2;
    pointer-events: all; /* Enable blocking during resize */
}
