.container {
  padding: 8px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.indent {
  padding-left: 10px;
  min-width: fit-content;
}

/* Base styles for both file and folder items */
.fileItem, .folderItem {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  color: #eee;
  white-space: nowrap;
  min-width: fit-content; /* Add this to prevent text cutting */
  width: auto; /* Change from 100% to auto */
  box-sizing: border-box;
}

.fileItem:hover, .folderItem:hover {
  background-color: #333
}

/* Specific styles for folder items */
.folderItem {
  font-weight: 500;
}

/* Icon styles */
.chevronIcon {
  width: 12px;
  margin-right: 4px;
  color: #ccc;
}

.folderIcon {
  width: 16px;
  margin-right: 8px;
  color: #fbbd08;
}

.fileIcon {
  width: 16px;
  margin-right: 8px;
  color: #b8c5d9;
}

.activeFile {
  background-color: #555;
}