.screenCover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.blur {
    background-color: rgba(0, 0, 0, 0.8);  
    backdrop-filter: blur(5px);
}

.complete {
    background-color: #ddd;  
}