
/* for the sidebar itself */
.sidebarContainer {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 100%;
    padding-left: 10px;
    overflow: auto;
    box-sizing: border-box;
    background-color: #eee;
}

.sidebarHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    margin-top: 7px; 
    padding-right: 10px;

}
