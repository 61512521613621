.outputArea {
    background-color: #222;
    color: #F8F8F2;
    padding: 0 5px;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    transition: height 0.3s ease-in-out;
}

.outputArea.light {
    background-color: #fff;
    color: #000;
}

.codeOutputHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding-top: 3px;
    border-bottom: 1px solid #75715E;
}

.outputArea h4 {
    font-size: 14px;
    font-weight: bold;
}

.outputArea p {
    font-size: 12px;
}

.compilerResponse {
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
    border-radius: 5px;
    font-family: monospace;
    padding: 3px 0;
}

.compilerResponse pre{
    margin: 0;
}
