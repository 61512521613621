.loaderContainer {
  display: flex;
  flex-direction: column;
  gap: 20px; /* between the loader and the text */
  justify-content: center;
  align-items: center;
}

.loaderText {
  font-size: 18px;
  font-weight: 500;
  color: #666;
  padding: 10px;
}

.centeredText {
  text-align: center;
}
