.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.modalContainer {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modalHeader h2 {
    margin: 0;
    font-size: 18px;
}

.closeButton {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #666;
    border-radius: 5px;
}

.closeButton:hover, .closeButton:focus {
    color: #333;
    outline: solid 1px #333;
}

.modalBody {
    margin-bottom: 20px;
    text-align: left;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.errorMessage {
    color: red;
    margin-top: 10px;
}

.successMessage {
    color: green;
    margin-top: 10px;
}