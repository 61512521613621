.ide {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #222;
    border: none;
    padding: 0 3px 3px 3px;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    font-family: Helvetica, Arial, sans-serif;
}

.ide.light {
    background-color: #fff;
}

.ideTopRibbon {
    background-color: #222;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 3px 5px;
    align-items: center;
    text-align: center;
    color: #ddd;
    gap: 10px;
    font-weight: bold;
    font-size: 12px;
}

.ideMiddleRibbon {
    background-color: #222;
    display: flex;
    flex-direction: row;
    padding: 3px 5px;
    justify-content: flex-end;
    gap: 10px;
    color: #fff;
    align-items: center;
    position: relative;
    font-size: 12px;
}

.ideMiddleRibbon.light {
    background-color: #fff;
}

.ideTopRibbon.light {
    background-color: #fff;
}

/* Groups of selectors */
.languageButtons, .uiButtons {
    display: flex;
    flex-direction: row;
    gap: 3px 10px;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
}

/* for the theme and language dropdowns*/
.ideDropdown {
    text-align: left;
    text-align-last: left;
    float: left;
    clear: both;
    outline: none;
    padding: 2px 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    min-width: 120px;
    box-sizing: border-box;
}

.ideDropdown.dark {
    border: 1px solid #999;
    background-color: #000;
    color: #ddd;
}

.ideDropdown.light {
    border: 1px solid #999;
    background-color: #666;
    color: #ffffff;
}

/* Question mark and info */

.questionMarkIcon {
    display: flex;
    position: relative;
    color: #999;
    align-items: center;
    font-size: 16px;
    cursor: help;
}

.questionMarkIcon:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

.tooltipText {
    visibility: hidden;
    background-color: #fff;
    color: #000;
    border: solid 1px #ddd;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 5;
    top: 30px;
    right: 0;
    transform: translateX(0);
    opacity: 0;
    transition: opacity 0.3s;
    min-width: 180px;
    min-height: 60px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    font-size: 12px;
    max-width: 400px;
    font-weight: 400;
    font-family: 'Segoe UI', sans-serif;
}

/* left tab with fileExplorer */

.leftTab {
    display: flex;
    min-width: 230px;
    border-right: 2px solid var(--border-color-dark);
    box-sizing: border-box;
    position: relative;
    transition: all 0.3s ease-in-out;
}

/* Adjust border color for light mode */
.ide.light .leftTab {
    border-right: 2px solid var(--border-color-light);
}

.leftTab.hidden {
    min-width: 20px;
}

/* toggle button for left tab visibility */
.toggleBtn {
    position: absolute;
    top: 5px; 
    right: 5px; /* Place just inside the tab */
    width: 20px; 
    background: transparent; /* Button background */
    color: #999;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    font-size: 16px;
    padding: 0;
}

.toggleBtn:hover,
.toggleBtn:focus {
    font-size: 16px;
    color: #666;
}

/* Main tab with editor and output */

.mainTab {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    box-sizing: border-box;
    flex-grow: 1; /* This will make the mainTab take the remaining width */
    width: calc(100% - 230px);
    transition: all 0.3s ease-in-out;
}

/* if file explorer is present but hidden */
.mainTab.wide {
    width: calc(100% - 20px);
}

/* if file explorer is not present */
.mainTab.full {
    width: 100%;
}

/* Popup to confirm user want to submit code */
.confirmSubmitModel {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    text-align: center;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
    z-index: 6;
    width: 200px;
}

.confirmSubmitModel.light {
    background-color: #eee;
    border: 1px solid #ccc;
}

.confirmSubmitModelTitle {
    font-weight: 700;
}

.confirmSubmitModel p {
    font-size: 13px;
}

.confirmSubmitModelButtons {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 10px;
}

/* Alerts for when editor is empty and user presses run or submit */
.emptyEditorAlert {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #fff;
    color: #333;
    padding: 10px;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
    white-space: wrap;
    z-index: 6;
    width: 150px;
    font-size: 14px;
    font-family: Helvetica, sans-serif;
    position: absolute;
    top: -130px;
    right: 60px;
}

.emptyEditorAlertTitle {
    font-weight: 700;
}

.emptyEditorAlert p {
    margin: 0;
    font-size: 13px;
}

.emptyEditorAlert::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    bottom: auto;
    margin-left: -11px; 
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #bababa; 
    border-top: 10px solid #fff; 
    border-bottom: none;
}

.emptyEditorAlert::after {
    content: ''; 
    position: absolute;
    top: 100%; 
    left: 50%; 
    bottom: auto;
    width: 0;
    height: 0;
    margin-left: -10px; 
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent;
    border-top: 10px solid #fff; 
    border-bottom: none;
}
    
/*scroll to bottom button*/
.scrollToBottomButton, .scrollToTopButton {
    position: absolute;
    right: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: rgba(176, 176, 176, 0.3); /* Semi-transparent grey */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 4; 
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
}

.scrollToBottomButton.light, .scrollToTopButton.light {
    background-color: #bbb; /* darker so you can see with the light theme */
}

.scrollToBottomButton:hover, .scrollToTopButton:hover {
    background-color: #246E24;
}

.scrollToBottomButton i, .scrollToTopButton i {
    color: #fff; 
}

/*autocomplete checkbox*/
.disabledAutoCheckbox {
    color: grey;
    display: flex;
    align-items: center;
}

.autoCheckbox {
    display: flex;
    align-items: center;
    border: solid 1px #999;
    border-radius: 5px;
    padding: 2px 5px;
    height: 15.5px; /* match the other dropdowns*/
}

.autoCheckbox input {
    margin: 0 3px;
}

/* For the exclmaition mark */
.autoCheckbox svg {
    color: #FFBF00;
    margin-left: 5px;
}

.autoCheckbox.light {
    border: 1px solid #666;
    background-color: #999;
    color: #ffffff;
}

.autocompleteButtonHoverMessage {
    position: absolute;
    top: 30px;
    left: 300px;
    color: #000;
    z-index: 7;
    font-size: 13px;
    font-weight: 400;
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

/* ExecuteCode tooltip */
.tooltip {
    position: absolute;
    z-index: 1;
    background-color: #ddd;
    border: 1px solid #999;
    color: #000;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    bottom: 120px; 
    right: 3px;
    display: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 5;
}

.executeButtonContainer:hover .tooltip {
    display: block;
}
  

