.progressBarContainer {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    text-align: center;
    margin: 5px;
    box-sizing: border-box;
    min-width: 250px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

/* temporary hide the progress bar on small screens */
@media (max-width: 500px) {
    .progressBarContainer {
        display: none;
    }
}

.sectionLabel {
    font-weight: bold;
    margin-bottom: 2px;
}

.progressBar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectionBar {
    height: 8px;
    border-radius: 3px;
    margin: 0 3px;
    flex-grow: 1;
    transition: background-color 0.5s ease; 
}

.passedSection {
    background-color: #b0d0b0; /* faded green */
}

.currentSection {
    background-color: #246E24; /* dark green */
}

.upcomingSection {
    background-color: #d6d6d6; /* gray */
}
