
.fontSizeControls {
    display: flex;
    align-items: center;
    border: solid 1px #999;
    padding: 2px 5px;
    border-radius: 5px;
    min-width: 50px;
    box-sizing: border-box;
}

.fontSizeControls.light {
    border: 1px solid #666;
    background-color: #999;
    color: #ffffff;
}

.fontSizeIndicator {
    margin: 0 8px; /* Adds spacing around the font size indicator */
}
