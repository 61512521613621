.schemaContainer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  
.mainHeading {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    border-bottom: 1px solid #75715E;
}

.table {
    margin-bottom: 5px;
}

.tableName {
    font-weight: bold;
    margin-bottom: 5px;
}

.tableSchema {
    width: 100%;
    border-collapse: collapse;
}

.tableSchema th, .tableSchema td {
    border: 1px solid #ddd;
    padding: 2px;
}

.tableSchema th {
    background-color: #333;
}
  

.reportSchemaTableRow {
    background-color: #333 !important;
}