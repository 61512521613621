/* Popup to confirm user action */
.confirmModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
}

.confirmModal {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.confirmModal.light {
    background-color: #eee;
    border: 1px solid #ccc;
}

.confirmModalTitle {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
}

.confirmModalMessage {
    font-size: 14px;
    margin-bottom: 20px;
}

.confirmModalButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}
