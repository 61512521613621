.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .audioVisualizerCanvas {
    box-sizing: border-box;
  }
  
  .statusText {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .userSpeaking {
    color: #246e24;
  }
  
  .aiSpeaking {
    color: #0000ff;
  }
  
  .listening {
    color: #00ff00;
  }