/*button is formatted in button.js*/

/*! icon*/
.problemIcon {
    height: 25px;
}

@media (max-width: 768px) {
    .problemIcon {
        height: 20px;
    }
}

/* Full box that appears when button is clicked */
.reportIssueBox {
    display: flex;
	position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 100;
	flex-direction: column;
	background-color: #EDEDED;
    width: 350px;
    min-height: 150px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    row-gap: 5px;
    border: 1px solid #000;
}

@media (max-width: 768px) {
    .reportIssueBox {
        width: calc(100% - 30px);
    }
}

/* box header */
.reportIssueBox h4 {
    margin: 0;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    background-color: #246E24;
    color: #fff;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

/* box text */

.reportIssueBox p {
    margin: 0;
    text-align: center;
    padding: 10px;
}

/* Form inside the box with the email and problem input */
.reportIssueBox form {
    display: flex;
    flex-direction: column;
    margin: 0 10px 5px 10px;
    font-family: 'Segoe UI', sans-serif;
}

.reportIssueBox label {
    font-weight: 500;
}

/*Email input*/
.reportIssueBox input[type="email"], .reportIssueBox input[type="tel"] {
    border: solid 1px #999;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    font-family: 'Segoe UI', sans-serif;
}

/*Problem description input*/
.reportIssueBox textarea {
    resize: none;
    border-radius: 5px;
    border: solid 1px #999;
    height: 100px;
    padding: 5px;
    font-size: 14px;
    overflow: auto;
    margin-bottom: 5px;
    font-family: 'Segoe UI', sans-serif;
}

/*Error messages*/
.reportIssueBox .errorMessage {
    color: red;
    font-weight: normal;
    padding: 0;
    margin-bottom: 5px;
}

/*Whatsapp permission checkbox*/
.whatsAppCheckbox {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: 10px 0;
    font-weight: bold;
    align-items: center;
}

/*Message box with spinner when sending message*/
.sendingMessageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; 
}
