.tableContainer {
    overflow-x: auto;
    cursor: default;
}

.popoutContainer {
    position: absolute;
    top: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    padding: 10px;
    width: auto; /* Adjust the width as needed */
    cursor: default;
}

.right {
    left: calc(100% - 10px); /* Adjust position to the left */
}
  
.left {
    right: calc(100% - 10px); /* Adjust position to the right */
}

.infoTable {
    width: 100%;
    border-collapse: collapse;
}

.infoTable th,
.infoTable td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
    min-width: 100px;
}

.rowHeader {
    white-space: nowrap;
    font-weight: 500;
    text-align: left !important;
}

.cell {
    text-align: center;
    font-weight: 400;
}

.checkIcon {
    color: green;
}

.timesIcon {
    color: red;
}
