.legalTextContainer {
    background-color: #fff;
    min-height: 100vh;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .legalText {
    padding-top: 25px;
    margin: 5px;
}

/*Tables*/
.table {
    width: 100%;
    border-collapse: collapse; /* This ensures that the borders between cells appear as a single line */
  }
  
  .table th,
  .table td {
    border: 1px solid #999; /* border for both headers and cells */
    padding: 5px;
    text-align: left;
  }
  
  .table th {
    background-color: #ccc; /* background for headers */
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Slightly different background for odd rows for better readability */
  }
  
  .table tbody tr td:first-child {
    font-weight: bold; 
  }
  