.timerContainer {
    display: flex;
    align-items: center;
    font-weight: bold;
    position: absolute;
    left: 75%;
    transform: translateX(-50%);
}

/* temporary hide the progress bar on small screens */
@media (max-width: 500px) {
    .timerContainer {
        display: none;
    }
}

.timerContainerRed {
    display: flex;
    align-items: center;
    font-weight: bold;
    position: absolute;
    left: 75%;
    transform: translateX(-50%);
    color: red;
}

.clockIcon {
    margin-right: 5px;
}