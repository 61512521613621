.chatbox {
    transition: all 0.5s ease;
    height: 100%;
    width: 100%;
    font-family: Helvetica, Arial, sans-serif;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    background-color: #eee;
    padding-bottom: env(safe-area-inset-bottom);
}

/* Additional styling for the chatbox when it is in intro state */
.chatboxIntro {
    padding: 10px 5px 0 5px;
    margin: auto;
    overflow-y: auto; 
}

.chatboxTest {
    padding: 3px;
}

/*inner box to hold the messages*/
.chatboxMessages {
    padding-right: 3px;
    height: calc(100% - 65px);
    margin: auto auto 0 auto;
    display: flex;
    flex-direction: column;
    font-family: Helvetica, Arial, sans-serif;
    max-width: 900px;
    overflow-y: auto;
    box-sizing: border-box;
}

.introChatMessages {
    height: calc(100% - 85px);
    padding: 0;
    overflow: auto;
}

/* The loader for when the initial messages are loading */
.chatMessagesLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

/*button to allow usser to scroll*/
.scrollDownIndicator {
    position: fixed;
    bottom: 80px;
    left: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(128, 128, 128, 0.5); /* Semi-transparent grey */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 4;
}

.scrollDownIndicator:hover {
    background-color: #246E24;
}

/* Buttons when time is up for user to decide if they will send the draft or not */
.timeUpButtons {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

/* Button for accessibility question */
.accessibilityQuestionButtons {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 10px;
    max-width: 900px;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: center;
}

/* Button for undoing the response to the accessibility question */
.undoAccessibilityResponseButton {
    color: #444;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    margin: 0;
    position: fixed;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    outline: none;
    border: none;
    background-color: transparent;
}
    
.undoAccessibilityResponseButton:hover, .undoAccessibilityResponseButton:focus {
    font-weight: bold;
}

/* Input for accessibility question */
.radioButtonInput {
    display: flex;
    position: fixed;
    bottom: 10px;
    width: 100%;
    margin: 0 auto;
    max-width: 900px;
}

.timeUpSendingLoader {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

}

/* Code editor */
.codeEditorMessage {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    min-height: 400px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.codeMessageHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    padding: 5px;
    background-color: #246e24;
    color: #fff;
}
