/* Define CSS variables for border colors */
:root {
    --border-color-dark: #000;
    --border-color-light: #999;
}

.sqlInterfaceContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: #272822;
}

/* Set the light theme background color */
.sqlInterfaceContainer.light {
    background-color: #ededed;
}

/* left tab with schema */
.leftTab {
    display: flex;
    width: 230px;
    border-right: 2px solid var(--border-color-dark);
    box-sizing: border-box;
    position: relative;
    transition: all 0.3s ease-in-out;
    font-size: 12px;
}

/* Adjust border color for light mode */
.sqlInterfaceContainer.light .leftTab {
    border-right: 2px solid var(--border-color-light);
}

.leftTab.hidden {
    min-width: 20px;
}

/* toggle button for left tab visibility */
.toggleBtn {
    position: absolute;
    top: 6px; 
    right: 0; /* Place just inside the tab */
    width: 20px; 
    background: transparent; /* Button background */
    color: #999;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    font-size: 16px;
}

.toggleBtn:hover,
.toggleBtn:focus {
    font-size: 16px;
    color: #666;
}

.mainTab {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    box-sizing: border-box;
    flex-grow: 1; /* This will make the mainTab take the remaining width */
    width: calc(100% - 230px);
    transition: all 0.3s ease-in-out;
}

.mainTab.wide {
    width: calc(100% - 20px);
}

.topRibbon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 3px 5px;
    align-items: center;
    color: #ddd;
    gap: 10px;
    font-weight: bold;
    box-sizing: border-box;
    background-color: #222;
    font-size: 12px;
}

/* Adjust top ribbon color for light mode */
.topRibbon.light {
    color: #000;
    background-color: #fff;
}

/* Groups of selectors */
.topRibbonLeft, .topRibbonRight {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

/* editor */

.editor {
    width: 100%;
    max-width: 100%;
    max-height: 90%;
    border-top: 2px solid var(--border-color-dark);
    box-sizing: border-box;
}

/* Adjust border color for light mode */
.sqlInterfaceContainer.light .editor {
    border-top: 2px solid var(--border-color-light);
}

/* middle ribbon */

.middleRibbon {
    display: flex;
    flex-direction: row;
    padding: 3px 5px;
    justify-content: flex-end;
    gap: 10px;
    background-color: #222;
    color: #fff;
    align-items: center;
    position: relative;
    font-size: 12px;
    border-top: 2px solid var(--border-color-dark);
}

.middleRibbon.light {
    color: #000;
    background-color: #fff;
    border-top: 2px solid var(--border-color-light);
}

.ideMiddleRibbon.light {
    color: #000;
}

/* loader when code is executing */

.sqlExecuteLoader {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-weight: 400;
}

.sqlExecuteLoader p {
    margin: 0 0 0 5px;
    font-size: 12px;
    color: #fff;
}

.sqlExecuteLoader.light p {
    color: #000;
}

/* output */

.output {
    width: 100%;
    max-width: 100%;
    border-top: 2px solid var(--border-color-dark);
    box-sizing: border-box;
}

/* Adjust border color for light mode */
.sqlInterfaceContainer.light .output {
    border-top: 2px solid var(--border-color-light);
}

.logOutput {
    width: 100%;
    max-width: 100%;
    border-top: 2px solid var(--border-color-dark);
    box-sizing: border-box;
    overflow: auto;
}

/* Adjust border color for light mode */
.sqlInterfaceContainer.light .logOutput {
    border-top: 2px solid var(--border-color-light);
}
