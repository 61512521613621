.buttonWithPopout {
    position: relative;
    display: inline-block;
}

.actionButton {
    background: none;
    color: #333;
    font-size: 13px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    text-align: left;
    font-weight: 700;
    font-family: 'Segoe UI', sans-serif;
}

.hoverComponent {
    display: flex;
}

.actionButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.actionButton:hover, .actionButton:focus {
    outline: solid 1px #333;
    color: #000;
}
.redText {
    color: red;
}

.greenText {
    color: green;
}

.whiteText {
    color: white;
}

.redText:hover, .redText:focus {
    color: red;
}

.greenText:hover, .greenText:focus {
    color: green;
}

.whiteText:hover, .whiteText:focus {
    color: white;
}

