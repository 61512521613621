/* Container for the dropdown */
.dropdownContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}
  
/* Label for the dropdown */
.dropdownContainer label {
    font-weight: bold;
    margin-bottom: 5px;
}
  
/* Basic dropdown style */
.dropdownContainer select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    min-width: 100px;
    cursor: pointer;
    transition: border-color 0.3s ease;
}
  
/* Hover effect for the dropdown */
.dropdownContainer select:hover, .dropdownContainer select:focus {
    border-color: #000;
}
  
/* Disabled option style */
.dropdownContainer select:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}
  
/* Dropdown option style */
.dropdownContainer option {
    padding: 5px;
    background-color: #fff;
}
  
.searchInput {
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.searchInput:focus {
    outline: solid 1px #000;
}

.combobox {
    position: relative;
    width: 100%;
}

.comboboxInput {
    width: 100%;
    padding: 5px 30px 5px 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
}

.comboboxInput:focus {
    outline: solid 1px #000;
}

.dropdownToggle {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 12px;
    color: #666;
    cursor: pointer;
    padding: 0;
}

.dropdownList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 4px 0 0;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.dropdownList li {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdownList li:hover {
    background-color: #f5f5f5;
}

.dropdownList li.selected {
    background-color: #e6f3ff;
}

.noResults {
    padding: 8px 12px;
    color: #666;
    font-style: italic;
}

.comboboxInput:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

.dropdownItem {
    padding: 8px 12px;
    cursor: pointer;
}

.focused {
    background-color: #e8e8e8;
    outline: none;
    color: black;
}

.selected {
    background-color: #f0f0f0;
}

.focused.selected {
    background-color: #e0e0e0;
}