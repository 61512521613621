.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    gap: 10px;
    padding: 20px;
    padding-top: 30px;
    border-radius: 5px;
    background-color: #eee;
    max-width: 450px;
    flex-grow: 1;
    border: solid 1px #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    font-family: 'Segoe UI', sans-serif;
    font-size: 14px;
    max-height: 95vh;
    overflow: auto;
    box-sizing: border-box;
}

.wide {
    max-width: 95%;
    min-width: 500px;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
    color: #666;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: transparent;
    font-weight: bold;
    margin: 5px 10px;
    border-radius: 5px;
}

.closeButton:hover {
    outline: 1px solid #333;
    color: #333;
}


.modalHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.modalTitle {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.modalSubtitle {
    font-size: 13px;
    font-weight: 500;
    color: #666;
}

.radioGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.optionLabel {
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}

.label {
    display: block;
    margin-bottom: 10px;
}

.input {
    padding: 8px;
    width: calc(100% - 16px);
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.generateLinkButtonContainer {
    display: flex;
    justify-content: center;
}

.generateLinkNote {
    font-size: 13px;
    text-align: left;
    margin-left: 25px;
    color: #666;
    box-sizing: border-box;
}

.loadingSpinner {
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* tabs */

.tabs {
    display: flex;
    border-bottom: solid 1px #ccc;
}

.tabButton {
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'Segoe UI', sans-serif;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    color: #666;
    border-bottom: 2px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tabButton:hover {
    border-bottom: 2px solid #333;
    color: #000;
}

.tabButton.active {
    border-bottom: 2px solid #333;
    color: #000;
}

.tabContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    min-height: 0; /* Important for flex child scrolling */
}

.tabDescription {
    margin-bottom: 10px;
    font-size: 13px;
    color: #333;
}

.contentSubheader {
    font-weight: 500;
}

/* error and success messages */

.errorMessage {
    color: red;
    margin-top: 5px;
    text-align: center;
}

.successMessage {
    color: green;
    margin-top: 5px;
    text-align: center;
}

/* inputs */

.calendarInput {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.calendarInput input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* invite email table */

.emailTableContainer {
    display: flex;
    overflow: auto;
    max-height: calc(100vh - 600px);
    min-height: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.emailTable {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
}

.emailTableHeader, .emailTableBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.emailTableHeader {
    border-bottom: 1px solid #ccc !important;
}

.emailTableHeader tr, .emailTableBody tr {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.emailTableHeader tr:hover, .emailTableBody tr:hover {
    background-color: #fff;
}

.emailTableHeader th, .emailTableBody td {
    display: flex;
    padding: 10px;
    text-align: left;
    box-sizing: border-box;
    align-items: center;
    background-color: #fff;
}

.emailTableBody td {
    border: none;
}

.emailFieldContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.emailInput, .nameInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
}

.emailContentContainer {
    flex: 1;
    min-height: 200px; 
    display: flex;
    flex-direction: column;
}

.firstColumn, .middleColumn, .lastColumn {
    flex: 1;
    box-sizing: border-box;
}

.lastColumn {
    max-width: 130px;
}

.plusIcon {
    margin-right: 5px;
}


/* emails that have been accepted */

.acceptedRow {
    border-bottom: solid 1px #ccc;
}

.acceptedInput {
    color: #555; /* Darker text color */
    border: none; /* Remove the border */
    pointer-events: none; /* Prevent interaction */
    padding: 10px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.acceptedInput:disabled {
    color: #555;
    border: none;
}

/* email inputs */

.inputGroup {
    margin-bottom: 5px;
}

.inputGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.inputGroup input,
.inputGroup select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-sizing: border-box;
}

.emailInputWrapper {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.emailInputWrapper input {
    flex: 1;
    border: none;
    padding: 5px;
    font-size: 14px;
    outline: none;
    min-width: 150px;
    background-color: transparent;
}

.emailTag, .selectedUserTag {
    display: inline-flex;
    align-items: center;
    background-color: #bdf7cd;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    font-size: 14px;
    width: fit-content;
}

.emailTag button, .selectedUserTag button {
    margin-left: 5px;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: #333;
    border-radius: 5px;
}

.emailTag button:hover, .selectedUserTag button:hover {
    color: #000;
    outline: solid 1px #000;
}

/* company tests */

.testName {
    font-weight: 400;
    color: #333;
    font-size: 13px;
}

/* add test modal */
.topRibbon {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.testDropdown {
    margin: 0;
}